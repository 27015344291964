import React from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import vision from '../images/vision-icon.svg';
import mission from '../images/mission-icon.svg';
import AboutPageStory from './AboutPageStory';
import FounderWord from './FounderWord';
import OurTeam from './OurTeam';
import Questions from './Questions';
import JoinCommunity from './JoinCommunity';




const About = () => {
  return (
    <div>
      <div className='container padding-2 about-us-bg'>
        <div className='columns about-flex is-vcentered is-centered'>
          <div className='is-12-tablet is-5-desktop column'>
            <div className='about-title'>
              <h1 className="title-home">
                <Trans>
                  <span className="title-home title-home-africa">Qui&nbsp;</span>
                  <span className="title-home title-home-is-calling">sommes</span>
                  <br className="br-desktop" />
                  <span className="title-home title-home-you">nous ?</span>
                </Trans>
              </h1>
              <div className='Quisommes-responcive'>
                <StaticImage
                  src='../images/a-propos-2.webp'
                  alt="Fatou et Oumou, membres Repat Africa"
                  className="predominant-image"
                />
              </div>
              <p className="has-text-justified">
                <Trans>
                  Repat Africa est une organisation dédiée à favoriser et concrétiser les <strong>investissements</strong> et les <strong>migrations</strong> vers l'Afrique.
                </Trans>&nbsp;
                <Trans>
                  Créée en 2020, Repat Africa œuvre pour le <strong>développement</strong> entrepreneurial et économique du continent africain.
                </Trans>&nbsp;
                <Trans>
                  Nous agissons selon les principes d'indépendance, de solidarité et de respect mutuel, sans aucune discrimination ethnique, sociale, religieuse ou de genre.
                </Trans>
              </p>
            </div>
          </div>
          <div className='is-12-tablet is-6-desktop column Quisommes-res pl-desktop-6'>
            <div className='Quisommes'>
              < StaticImage
                  src='../images/a-propos-2.webp'
                  alt="à propos de Repat Africa"
                  className="predominant-image  is-pulled-right"
                  loading="eager"
                  width={592}
                  height={362}
              />
            </div>
          </div>
        </div>
        <div className='columns'>
          <div className='is-6-tablet is-6-desktop column'>
            <div className='vision-box'>
              <div className='vision-card'>
                <div>
                  <img src={vision} alt='img' />
                </div>
                <h6><Trans>Vision</Trans></h6>
                <p><Trans>Notre vision est celle d'une Afrique qui dispose d'une position économique de premier plan.</Trans></p>
              </div>
            </div>
          </div>
          <div className='is-6-tablet is-6-desktop column'>
            <div className='vision-box'>
              <div className='vision-card'>
                <div>
                  <img src={mission} alt='img' />
                </div>
                <h6><Trans>Mission</Trans></h6>
                <p><Trans>Promouvoir et concrétiser l'entrepreneuriat, l'investissement et les migrations vers l'Afrique.</Trans></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutPageStory />
      <FounderWord/>
      <OurTeam />
      <Questions />
      <JoinCommunity />
    </div >
  )
}

export default About;
