
import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const LegalNoticeTab = () => {
  const [activeTab, setActiveTab] = useState('Pictures');
  return (
    <section className='LegalNoticeTab-section'>
      <div className='container'>
        <div className='LegalNoticeTab'>
          <div className="eventTabs">
            <div className="columns is-desktop" style={{ display: "block" }}>
              <div className="column is-narrow-desktop">
                <div className="tabs is-boxed">
                  <ul className="tabsList event-tabsList">
                    <li className={activeTab === 'Pictures' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Pictures')}>
                        Repat Africa
                      </a>
                    </li>
                    <li className={activeTab === 'Music' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Music')}>
                        Propriété intellectuelle
                      </a>
                    </li>
                    <li className={activeTab === 'Hypertext' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Hypertext')}>
                        Liens hypertextes
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="tab-content event-tab-contant">
                  <div className={`tab-pane ${activeTab === 'Pictures' ? 'is-active' : ''}`} id="Pictures">
                    <div className="eventtabContent">
                      <div className=' RepatAfrica-details'>
                        <div className='RepatAfrica_add'>
                          <h3>Adresse :</h3>
                          <p>Association loi 1901 66 avenue des Champs Elysées 75008 Paris</p>
                        </div>
                        <div className='RepatAfrica_add'>
                          <h3>Siret :</h3>
                          <p>88516291700011</p>
                        </div>
                        <div className='RepatAfrica_add'>
                          <h3>Directeur de la publication :</h3>
                          <div className='user-details'>
                            <div className='userImage '>
                              <StaticImage
                                src='../images/TeamMember/kara.png'
                              />

                            </div>
                            <div className='userDetails '>
                              <p>
                                Kara Diaby
                              </p>
                              <span>
                                Président
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='RepatAfrica_add email-user'>
                          <h3>E-mail:</h3>
                          <p>hello[at]repat.africa</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'Music' ? 'is-active' : ''}`} id="Music">
                    <div className="eventtabContent Intellectual-details">
                      <p className='mb-3'>
                        Le site https://www.repat.africa est protégé par les dispositions du Code de la propriété intellectuelle, notamment par celles de ses dispositions relatives à la propriété littéraire et artistique, aux droits d'auteur et à la protection des bases de données.
                      </p>
                      <p className='mb-3'>
                        Ces droits sont la propriété exclusive de REPAT AFRICA. Le Code de la propriété intellectuelle n'autorise aux termes de l'article L.133-5, d'une part, que les copies ou reproductoins strictement réservées à l'usage privé du copiste et non destinées à une utilisation collective et, d'autre part, que les analyses eet les courtes citations dans un but d'exemple et d'illustration.
                      </p>
                      <p className='mb-3'>
                        De même, l'article L.342-3 du Code de la propriété intellectuelle n'autorise que l'extraction ou la réutilisation d'une partie non substantielle du contenu d'une base de donnée mise à la disposition du public par son titulaire. Par conséquent, toute représentation ou reproduction non autorisée des sites de REPAT AFRICA, par quelque moyen que ce soit, est strictement interdite sous peine de poursuites judiciaires. Le contrevenant s'expose à des sanctions civiles et pénales et notamment aux peines prévues aux articles L.335-2 et L.343-1 du Code de la propriété intellectuelle.
                      </p>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'Hypertext' ? 'is-active' : ''}`} id="Hypertext">
                    <div className="eventtabContent Intellectual-details">
                      <p>
                        Bien que nous apportons la plus grande rigueur dans la sélections des liens que nous proposons vers d'autres sites du réseau internet, REPAT AFRICA ne saurait être tenu responsable du contenu présent sur ces sites.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LegalNoticeTab
