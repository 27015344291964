import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from '@lingui/macro';


const RoadMapRepatAfrica = () => {
  return (
    <section className='RoadMapRepatAfrica-Section'>
      <div className='container padding-2 mt-5'>
        <div className='columns'>
          <div className='column RoadMapRepatAfricaImg_dek'>
            <div className='RoadMapRepatAfrica_img'>
              <StaticImage
                src='../images/lisboa-15may-068-665f262e9d7b0.webp'
                title="L'image qui décrit visuellement notre roadmap"
                alt="Femme roadmap"
                className="radius-8"
              />
            </div>
          </div>
          <div className='column '>
            <div className='RoadMapRepatAfrica_heading'>
              <h2><Trans>Pourquoi cette roadmap ?</Trans></h2>
              <p className="has-text-justified">
                <Trans>
                  Notre feuille de route est un résumé du développement de Repat Africa qui a été organisé en quatre époques : Osei Tutu, Agojie Dahomey, Shaka Zulu et Mansa Moussa. Chaque ère est centrée sur un ensemble de fonctionnalités et d’accomplissements.
                </Trans>
              </p>
            </div>
            <div className="RoadMapRepatAfrica_Boxs">
              <div className='RoadMapRepatAfrica_Boxmain RoadMapRepatAfrica_Green'>
                <div className='RoadMapRepatAfrica_BoxmainSvg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129" fill="none">
                    <path d="M83.6017 78.4453C87.4989 74.1795 90.0074 68.6236 90.4122 62.4927C90.4751 61.5405 89.7109 60.7364 88.7565 60.7364H78.9766" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M73.1016 85.4531C75.9195 84.439 78.5139 82.9569 80.7911 81.1003" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M49.4393 60.7321H39.6594C38.705 60.7321 37.9408 61.5361 38.0036 62.4885C38.7041 73.0979 45.7049 81.9857 55.2992 85.4453" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.0078 82.9492C81.1761 79.7317 87.7499 70.9995 87.7499 60.7319" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M40.668 60.7319C40.668 70.9995 47.2417 79.7316 56.41 82.9492" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M56.3281 73.2228C58.6093 74.6651 61.3128 75.5 64.2112 75.5C68.9449 75.5 73.1586 73.2731 75.8621 69.8099C77.8162 67.3061 78.9809 64.1545 78.9809 60.7313V52.4297C78.9809 49.1713 77.3565 46.1265 74.6498 44.3108C72.1312 42.6217 69.3052 41.6064 66.4169 41.2646" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M62.0016 41.2693C59.1133 41.611 56.2872 42.6264 53.7686 44.3154C52.4147 45.2233 51.3325 46.4387 50.5885 47.8338C49.8434 49.2299 49.4375 50.8046 49.4375 52.4343L49.4375 60.736C49.4375 64.1592 50.6022 67.3108 52.5563 69.8145C52.8456 70.1852 53.1523 70.5417 53.4751 70.8828" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M60.0012 74.8945L55.0637 85.9739C54.4298 87.3963 55.4708 89 57.0281 89H71.3884C72.9458 89 73.9868 87.3963 73.3529 85.9739L68.4155 74.8945" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M64.2084 89H59.582L64.2084 75.5027L68.8348 89H64.2084Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M49.4375 60.4878C49.4375 63.8363 52.1519 66.5508 55.5005 66.5508C58.849 66.5508 61.5635 63.8363 61.5635 60.4878" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M78.9775 60.4878C78.9775 63.8363 76.2631 66.5508 72.9145 66.5508C69.566 66.5508 66.8516 63.8363 66.8516 60.4878" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.5155 57.2921C72.6472 57.2758 72.7811 57.2673 72.9172 57.2673C74.6965 57.2673 76.1389 58.7098 76.1389 60.4892C76.1389 62.2685 74.6964 63.7109 72.9172 63.7109C71.1378 63.7109 69.6953 62.2684 69.6953 60.4892C69.6953 60.1975 69.7341 59.9148 69.8068 59.6461" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M55.5031 57.2672C57.2825 57.2672 58.725 58.7097 58.725 60.4891C58.725 62.2685 57.2825 63.7109 55.5031 63.7109C53.7237 63.7109 52.2812 62.2685 52.2812 60.4891C52.2812 58.7097 53.7237 57.2672 55.5031 57.2672Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M41.5031 50.3336C43.2825 50.3336 44.725 51.7761 44.725 53.5555C44.725 55.3349 43.2825 56.7773 41.5031 56.7773C39.7237 56.7773 38.2812 55.3349 38.2812 53.5555C38.2812 51.7761 39.7237 50.3336 41.5031 50.3336Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M41.5015 56.7782C42.594 56.7782 43.4796 57.6639 43.4796 58.7563C43.4796 59.8488 42.594 60.7344 41.5015 60.7344C40.409 60.7344 39.5234 59.8488 39.5234 58.7563C39.5234 57.6639 40.409 56.7782 41.5015 56.7782Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M45.7494 64.3802C46.6249 64.3802 47.3347 65.0899 47.3347 65.9655C47.3347 66.841 46.6249 67.5508 45.7494 67.5508C44.8738 67.5508 44.1641 66.841 44.1641 65.9655C44.1641 65.0899 44.8738 64.3802 45.7494 64.3802Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M48.7845 70.5599C49.6601 70.5599 50.3698 71.2696 50.3698 72.1452C50.3698 73.0207 49.6601 73.7305 48.7845 73.7305C47.909 73.7305 47.1992 73.0207 47.1992 72.1452C47.1992 71.2696 47.909 70.5599 48.7845 70.5599Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M53.804 75.2708C54.6796 75.2708 55.3894 75.9806 55.3894 76.8561C55.3894 77.7316 54.6796 78.4414 53.804 78.4414C52.9285 78.4414 52.2188 77.7316 52.2188 76.8561C52.2188 75.9806 52.9285 75.2708 53.804 75.2708Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M82.6712 64.3802C83.5468 64.3802 84.2565 65.0899 84.2565 65.9655C84.2565 66.841 83.5468 67.5508 82.6712 67.5508C81.7957 67.5508 81.0859 66.841 81.0859 65.9655C81.0859 65.0899 81.7957 64.3802 82.6712 64.3802Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M79.6361 70.5599C80.5116 70.5599 81.2214 71.2696 81.2214 72.1452C81.2214 73.0207 80.5116 73.7305 79.6361 73.7305C78.7605 73.7305 78.0508 73.0207 78.0508 72.1452C78.0508 71.2696 78.7605 70.5599 79.6361 70.5599Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M74.6126 75.2708C75.4882 75.2708 76.1979 75.9806 76.1979 76.8561C76.1979 77.7316 75.4882 78.4414 74.6126 78.4414C73.7371 78.4414 73.0273 77.7316 73.0273 76.8561C73.0273 75.9806 73.7371 75.2708 74.6126 75.2708Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M86.9172 50.3336C88.6966 50.3336 90.139 51.7761 90.139 53.5555C90.139 55.3349 88.6966 56.7773 86.9172 56.7773C85.1378 56.7773 83.6953 55.3349 83.6953 53.5555C83.6953 51.7761 85.1378 50.3336 86.9172 50.3336Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M86.9156 56.7782C88.008 56.7782 88.8936 57.6639 88.8936 58.7563C88.8936 59.8488 88.008 60.7344 86.9156 60.7344C85.8231 60.7344 84.9375 59.8488 84.9375 58.7563C84.9375 57.6639 85.8231 56.7782 86.9156 56.7782Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M61.5664 60.4883V56.7889C61.5664 55.3288 62.7501 54.1451 64.2102 54.1451C65.6703 54.1451 66.854 55.3288 66.854 56.7889V60.4883" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M69.1119 51.2852L59.3093 51.2852C58.0296 51.2852 56.7958 50.8094 55.8473 49.9503C55.1236 49.2948 55.1239 48.1577 55.8479 47.5025C56.4932 46.9186 57.4809 46.9359 58.1054 47.5421C58.691 48.1105 59.4752 48.4284 60.2913 48.4284H68.1295C68.9458 48.4284 69.7298 48.1104 70.3155 47.542C70.94 46.9358 71.9277 46.9185 72.5729 47.5024C73.2971 48.1576 73.2974 49.2946 72.5737 49.9502C71.6255 50.8094 70.3915 51.2852 69.1119 51.2852Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M66.4152 48.4297H62.0039V40.5166C62.0039 39.9578 62.4569 39.5048 63.0157 39.5048H65.4035C65.9623 39.5048 66.4152 39.9578 66.4152 40.5166V48.4297Z" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.3594 60.4883H73.4719" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M54.9453 60.4883H56.0578" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M52.5547 55.1719C52.5547 55.1719 53.4851 54.5186 55.5028 54.5186C57.3258 54.5186 58.4345 55.1719 58.4345 55.1719" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M75.8642 55.1719C75.8642 55.1719 74.9337 54.5186 72.9161 54.5186C71.0931 54.5186 69.9844 55.1719 69.9844 55.1719" stroke="#459B73" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className='RoadMapRepatAfrica_BoxmainDetails'>
                  <p><Trans>Maintenir notre position de leader dans l'engagement des diasporas</Trans></p>
                </div>
              </div>
              <div className='RoadMapRepatAfrica_Boxmain RoadMapRepatAfrica_Yellow'>
                <div className='RoadMapRepatAfrica_BoxmainSvg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129" fill="none">
                    <path d="M53.9848 43.3251V43.2258C53.9848 40.9086 55.383 38.9181 57.3817 38.0513C57.5394 37.9829 57.7183 37.9829 57.8761 38.0513C59.3374 38.6851 60.4769 39.9198 60.9844 41.4435" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M68.02 41.4435C68.5275 39.9198 69.667 38.6851 71.1283 38.0513C71.286 37.9829 71.4649 37.9829 71.6227 38.0513C73.6213 38.9182 75.0195 40.9086 75.0195 43.2258V43.3251" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M64.5039 43.6406V53.4261" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M59.402 53.4287V46.1889C59.402 42.9041 61.4088 40.0884 64.2631 38.8997C64.4174 38.8355 64.59 38.8355 64.7444 38.8997C67.5986 40.0884 69.6055 42.9041 69.6055 46.1889V53.4287H59.402Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M77.957 49.6535V53.4251H73.0064V46.9389C73.0064 44.591 74.9098 42.6875 77.2579 42.6875H77.3497C77.6851 42.6875 77.957 42.9594 77.957 43.2948V45.9621" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M51.6567 42.6875H51.7486C54.0965 42.6875 56 44.5909 56 46.9389V53.4251H51.0494V43.2949C51.0494 42.9594 51.3213 42.6875 51.6567 42.6875Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M65.7604 90.1453C64.9621 90.5088 64.0451 90.5088 63.2468 90.1453C56.0521 86.8682 51.0502 79.6161 51.0502 71.1945V53.4258H77.957V71.1945C77.957 79.616 72.9551 86.8682 65.7604 90.1453Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M73.2109 69.1533C71.7099 68.414 70.6065 66.9826 70.324 65.2812C70.347 65.2816 70.3697 65.2831 70.3927 65.2831" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M73.2677 59.6591C73.9532 59.3312 74.7179 59.1445 75.525 59.1445H77.957" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M51.049 59.1445H53.4809C54.2881 59.1445 55.0526 59.3312 55.7383 59.6591" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M58.6797 65.2812C58.3972 66.9826 57.2938 68.414 55.7928 69.1533" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M62.861 75.7266H66.1406V78.7633H62.861V75.7266Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M62.861 89.9648V84.3438H66.1406V89.9648" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M66.3268 63.4611V60.6642C67.3272 59.5479 68.7778 58.8438 70.3948 58.8438C72.551 58.8438 74.4144 60.093 75.3038 61.9066C75.3519 62.0046 75.3519 62.1208 75.3038 62.2188C74.4143 64.0323 72.551 65.2816 70.3948 65.2816C68.7777 65.2816 67.3271 64.5775 66.3268 63.4611Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M68.6715 62.0625C69.1551 62.4346 69.7551 62.6448 70.3944 62.6448C71.0337 62.6448 71.6337 62.4347 72.1172 62.0626" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M53.7027 61.9066C54.5922 60.093 56.4555 58.8438 58.6117 58.8438C60.2287 58.8438 61.6793 59.548 62.6797 60.6642V63.4611C61.6793 64.5775 60.2287 65.2816 58.6117 65.2816C56.4555 65.2816 54.5921 64.0323 53.7027 62.2188C53.6546 62.1208 53.6546 62.0046 53.7027 61.9066Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M56.8903 62.0625C57.3738 62.4346 57.9738 62.6448 58.6131 62.6448C59.2525 62.6448 59.8524 62.4347 60.3359 62.0626" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M66.6542 75.7254C66.4822 75.3535 66.3856 74.9398 66.3856 74.5031C66.3856 73.3988 67.001 72.44 67.9062 71.9453" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M61.0965 71.9453C62.0019 72.44 62.6172 73.3988 62.6172 74.5031C62.6172 74.9398 62.5206 75.3536 62.3486 75.7254" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M59.971 74.0169L62.2369 69.8628C62.5288 69.3275 62.6819 68.7275 62.6819 68.1178V53.4258H66.3259V68.1178C66.3259 68.7275 66.479 69.3275 66.7709 69.8628L69.0368 74.0169C69.4573 74.7878 68.8993 75.7278 68.0211 75.7278H60.9868C60.1084 75.7278 59.5505 74.7878 59.971 74.0169Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M61.4021 84.3456H67.6046C69.139 84.3456 70.3945 83.0901 70.3945 81.5556C70.3945 80.0211 69.139 78.7656 67.6046 78.7656H61.4021C59.8676 78.7656 58.6121 80.0211 58.6121 81.5556C58.6121 83.0901 59.8676 84.3456 61.4021 84.3456Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M77.0625 77.2448C75.4945 77.0115 74.1471 76.1045 73.3222 74.8281" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.5027 71.2303C72.8335 68.5619 75.1073 66.4961 77.8652 66.4961C77.896 66.4961 77.9263 66.4979 77.957 66.4984" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M51.0496 66.4984C51.0803 66.4979 51.1107 66.4961 51.1415 66.4961C54.1268 66.4961 56.5469 68.9162 56.5469 71.9015C56.5469 74.6142 54.5485 76.8597 51.9436 77.2473" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M66.6133 45.1016L64.5039 47.2109L62.3945 45.1016" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M66.6133 49.0547L64.5039 51.1641L62.3945 49.0547" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M75.3125 56.293H74.9481" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.3359 56.293H71.9715" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M69.3594 56.293H68.995" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M60.0078 56.293H59.6434" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M57.0312 56.293H56.6669" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M54.0547 56.293H53.6903" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M67.5391 81.5547H61.4655" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M47.9451 64.9454V72.4424C47.9451 73.5371 47.5195 74.5889 46.7585 75.3757L43.4763 78.7684L40.1941 75.3757C39.4329 74.5889 39.0075 73.5371 39.0075 72.4424V56.7155C39.0075 53.8317 40.7732 51.3607 43.2823 50.3236C43.4063 50.2723 43.5465 50.2723 43.6705 50.3236C46.1796 51.3608 47.9453 53.8318 47.9453 56.7155V61.254" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M43.4766 54.4883V83.8524" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M45.2148 57.5312L43.4746 59.2783L41.7344 57.5312" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M45.2148 61.875L43.4746 63.6221L41.7344 61.875" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M45.2148 66.2148L43.4746 67.9619L41.7344 66.2148" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M45.2148 70.5547L43.4746 72.3018L41.7344 70.5547" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M82.2488 75.3757C81.4876 74.5889 81.0622 73.5371 81.0622 72.4424V56.7155C81.0622 53.8317 82.8279 51.3608 85.337 50.3236C85.4609 50.2723 85.6012 50.2723 85.7251 50.3236C88.2342 51.3608 90 53.8318 90 56.7155V72.4424C90 73.5371 89.5744 74.5889 88.8134 75.3757L85.5312 78.7684L82.2488 75.3757Z" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M85.5312 54.4883V83.8524" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M87.2695 57.5312L85.5293 59.2783L83.7891 57.5312" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M87.2695 61.875L85.5293 63.6221L83.7891 61.875" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M87.2695 66.2148L85.5293 67.9619L83.7891 66.2148" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M87.2695 70.5547L85.5293 72.3018L83.7891 70.5547" stroke="#ECAE4C" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className='RoadMapRepatAfrica_BoxmainDetails'>
                  <p><Trans>Explorer de nouvelles opportunités de croissance</Trans></p>
                </div>
              </div>
              <div className='RoadMapRepatAfrica_Boxmain RoadMapRepatAfrica_red'>
                <div className='RoadMapRepatAfrica_BoxmainSvg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129" fill="none">
                    <g clip-path="url(#clip0_6934_9264)">
                      <path d="M59.7592 72.2707C60.8602 72.2707 61.7528 73.1632 61.7528 74.2642C61.7528 75.3653 60.8602 76.2578 59.7592 76.2578C58.6582 76.2578 57.7656 75.3653 57.7656 74.2642C57.7656 73.1632 58.6582 72.2707 59.7592 72.2707Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M60.8821 72.6133L63.9987 65.4309L57.7656 68.0377" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M60.0273 50.6277C58.4263 53.0946 56.6386 55.3516 54.8867 57.3281" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M45.8672 65.8281C45.8672 65.8281 48.776 63.6586 52.362 60.0263" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M42.0847 51.1196H39.819C39.5803 51.1196 39.3867 51.3131 39.3867 51.5519V56.927C39.3867 57.7915 40.0875 58.4922 40.9519 58.4922C41.8163 58.4922 42.517 57.7915 42.517 56.927V51.5519C42.517 51.3131 42.3235 51.1196 42.0847 51.1196Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M40.9531 64.8945V58.7507" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M48.1082 45.7368H45.8425C45.6037 45.7368 45.4102 45.9303 45.4102 46.1691V51.5442C45.4102 52.4086 46.1109 53.1094 46.9753 53.1094C47.8397 53.1094 48.5405 52.4086 48.5405 51.5442V46.1691C48.5405 45.9303 48.3469 45.7368 48.1082 45.7368Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M46.9727 59.75V53.2692" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M53.8152 38.7915H51.5495C51.3107 38.7915 51.1172 38.985 51.1172 39.2238V44.5989C51.1172 45.4633 51.8179 46.1641 52.6823 46.1641C53.5468 46.1641 54.2475 45.4633 54.2475 44.5989V39.2238C54.2475 38.985 54.054 38.7915 53.8152 38.7915Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M52.6836 53.9688V46.425" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M59.504 80.3748L56.9922 83.5547" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M51.1289 71.7266H54.1101" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M51.2539 61.6241L55.028 64.625" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M64 59.202V65.4336" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M63.9989 65.4344C65.4338 65.4344 66.8913 65.0535 68.1688 64.2917C69.7806 63.3306 71.847 63.6419 73.1039 65.0355C74.1644 66.2111 74.4165 67.908 73.7437 69.3413C72.4903 72.0112 72.6475 75.1297 74.1629 77.6602L75.0799 79.1916H72.9768C69.5567 79.1916 66.4419 81.1596 64.9734 84.2482L63.9989 86.25L63.0245 84.2481C61.5559 81.1594 58.4412 79.1915 55.0211 79.1915H52.918L53.835 77.6601C55.3504 75.1297 55.5075 72.0111 54.2542 69.3411C53.5814 67.9079 53.8335 66.211 54.8939 65.0354C56.1509 63.6418 58.2172 63.3305 59.8291 64.2916C61.1066 65.0535 62.564 65.4344 63.9989 65.4344Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M68.2397 72.2707C69.3407 72.2707 70.2332 73.1632 70.2332 74.2642C70.2332 75.3653 69.3407 76.2578 68.2397 76.2578C67.1386 76.2578 66.2461 75.3653 66.2461 74.2642C66.2461 73.1632 67.1386 72.2707 68.2397 72.2707Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M67.1166 72.6133L64 65.4309L70.2331 68.0377" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M85.9167 51.1196H88.1824C88.4212 51.1196 88.6147 51.3131 88.6147 51.5519V56.927C88.6147 57.7915 87.914 58.4922 87.0495 58.4922C86.1851 58.4922 85.4844 57.7915 85.4844 56.927V51.5519C85.4844 51.3131 85.6779 51.1196 85.9167 51.1196Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M87.0469 64.8945V58.7507" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M79.8933 45.7368H82.1589C82.3977 45.7368 82.5913 45.9303 82.5913 46.1691V51.5442C82.5913 52.4086 81.8905 53.1094 81.0261 53.1094C80.1617 53.1094 79.4609 52.4086 79.4609 51.5442V46.1691C79.4609 45.9303 79.6545 45.7368 79.8933 45.7368Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M81.0273 59.6328V53.2691" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M73.7539 40.1281V39.2242C73.7539 38.9859 73.9469 38.7918 74.1863 38.7918H76.4518C76.6902 38.7918 76.8842 38.9859 76.8842 39.2242V44.5989C76.8842 45.4638 76.1829 46.1641 75.3191 46.1641C74.8866 46.1641 74.4954 45.989 74.2127 45.7063C73.929 45.4226 73.7539 45.0313 73.7539 44.5989V43.8195" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M75.3164 53.8516V46.425" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M68.4961 80.3748L71.0079 83.5547" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M76.8718 71.7266H73.8906" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M76.851 61.5159L72.9922 64.6016" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M66.9891 81.4062L64.0004 76.4358L61.0117 81.4062" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M82.1311 65.8242C82.1311 65.8242 73.8113 59.6196 67.9727 50.6246" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M87.7511 65.8246H82.1328V69.0234" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M81.8945 76.0547C82.0527 75.0939 82.135 74.1077 82.135 73.1026V72.7177" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M56.7734 89.7133C58.9883 90.6762 61.432 91.2109 64.0002 91.2109C66.4766 91.2109 68.8391 90.7142 70.9906 89.8145C73.1432 88.9138 75.0849 87.6123 76.7229 86.0008" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M53.5473 87.8984C52.6825 87.2888 51.8725 86.6043 51.1289 85.8555" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M87.7538 65.8243C87.7538 65.8243 75.2862 55.4373 68.955 46.6319C68.6119 46.1546 67.8595 46.3966 67.8595 46.9844V55.0144C67.8595 57.1669 66.1544 58.9119 64.0019 58.9119C61.8494 58.9119 60.1443 57.1669 60.1443 55.0144V46.9844C60.1443 46.3967 59.3919 46.1546 59.0488 46.6319C52.7176 55.4372 40.25 65.8243 40.25 65.8243H45.8688V73.0988C45.8688 74.104 45.951 75.0902 46.1089 76.0508" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M70.9822 55.184C70.9822 59.0586 67.8735 62.2109 63.9989 62.2109C60.1243 62.2109 57.0156 59.0587 57.0156 55.184" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M77.1689 61.8234V73.1021C77.1689 80.3519 71.2482 86.25 63.9985 86.25C56.7488 86.25 50.8281 80.3519 50.8281 73.1021L50.8281 61.8234" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M48.497 79.204C48.5578 78.9579 48.58 78.6977 48.5577 78.4309C48.447 77.1163 47.2916 76.1401 45.9769 76.2508L45.4615 76.2941C43.3056 76.4755 41.262 77.3327 39.6216 78.7434C39.3426 78.9834 39.3799 79.426 39.695 79.616C40.788 80.2747 41.9871 80.7201 43.2293 80.9375" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M49.9862 82.4866C50.2266 82.6012 50.4518 82.7582 50.6506 82.9577C51.5818 83.8923 51.5791 85.4048 50.6444 86.336L50.278 86.7011C48.7453 88.2281 46.7698 89.2325 44.633 89.5713C44.2696 89.6289 43.9561 89.3143 44.015 88.951C44.2207 87.6829 44.6611 86.4725 45.3057 85.3787" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M49.7368 80.6313C49.1079 79.2734 47.4973 78.6825 46.1393 79.3114L45.607 79.558C43.3803 80.5894 41.5358 82.2972 40.3365 84.438C40.1325 84.8021 40.3442 85.2592 40.7539 85.3391C43.1625 85.8088 45.6579 85.5066 47.8845 84.4752L48.4168 84.2286C49.7748 83.5998 50.3657 81.9892 49.7368 80.6313Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M47.2773 81.7734L52.29 79.4516" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M79.5043 79.2115C79.4424 78.9634 79.4195 78.7011 79.4422 78.4321C79.5528 77.1174 80.7082 76.1413 82.0229 76.2519L82.5383 76.2953C84.6942 76.4767 86.7379 77.3338 88.3782 78.7446C88.6572 78.9845 88.6199 79.4272 88.3048 79.6171C87.2267 80.2669 86.0452 80.7092 84.8211 80.9297" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M77.6817 82.6791C77.5646 82.7603 77.4531 82.8532 77.3489 82.9578C76.4177 83.8924 76.4205 85.405 77.3552 86.3361L77.7215 86.7012C79.2542 88.2282 81.2297 89.2326 83.3666 89.5713C83.73 89.6289 84.0435 89.3143 83.9846 88.951C83.79 87.7521 83.3859 86.6048 82.7976 85.5588" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M78.2633 80.6313C78.8922 79.2734 80.5028 78.6825 81.8607 79.3114L82.393 79.558C84.6197 80.5894 86.4642 82.2972 87.6636 84.438C87.8676 84.8021 87.6559 85.2592 87.2462 85.3391C84.8376 85.8088 82.3422 85.5066 80.1155 84.4752L79.5832 84.2286C78.2253 83.5998 77.6344 81.9892 78.2633 80.6313Z" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M80.7196 81.7734L75.707 79.4516" stroke="#D33A44" strokeWidth="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_6934_9264">
                        <rect width="54" height="54" fill="white" transform="matrix(1 0 0 -1 37 92)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='RoadMapRepatAfrica_BoxmainDetails'>
                  <p><Trans>Développer des initiatives communautaires durables</Trans></p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='RoadMapRepatAfrica_img RoadMapRepatAfrica_responcive'>
          <StaticImage
            src='../images/lisboa-15may-068-665f262e9d7b0.webp'
            title="Notre roadmap"
            alt="Femme roadmap"
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            className="radius-8"
          />
        </div>
      </div>

    </section>
  )
}

export default RoadMapRepatAfrica
