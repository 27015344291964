import React from 'react';
// import BecomeMemberimg from '../images/BecomeMember.png';
import GooglePlay from '../images/GooglePlay.png';
import AppStore from '../images/AppStore.png';
import communityone from '../images/communityone.png';
import communitytwo from '../images/communitytwo.png';
import JoinCommunityBecomeimg from '../images/JoinCommunityBecomeimg.png';
import { Trans } from '@lingui/macro';



const JoinCommunityBecome = ({ locale }) => {
  return (
    <>
      <section className='JoinCommunityBecome-section community-section'>
        <div className='container align-center'>
          <div className='columns JoinCommunityBecome-center'>
            <div className='is-12-mobile is-12-tablet is-6-desktop column'>
              <div className='MembershipBonuses-heading'>
                <h4><Trans>Rejoignez la communauté</Trans></h4>
                <p><Trans>Participez au développement socio-économique de l'Afrique dès maintenant</Trans></p>
                <div className='app-paly'>
                  <img src={AppStore} />
                  <img src={GooglePlay} />
                </div>
              </div>
            </div>
            <div className='column'>
            <div className='communityImgs'>
              <div className='community_img_one'>
                <img src={communityone} />
              </div>
              <div className='community_img_two'>
                <img src={communitytwo} />
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinCommunityBecome;
