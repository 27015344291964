import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import i18n from '../i18n.js';
import { I18nProvider } from '@lingui/react';
import Layout from '../components/Layout.js';
import Seo from "../components/Seo.js";
import img from "../images/kara-rui.webp"
import InvestmentsHeroSection from '../components/InvestmentsHeroSection.js';
import OurInvestments_tab from '../components/OurInvestments_tab.js';

import Questions from '../components/Questions';
import JoinCommunity from '../components/JoinCommunity';
const OurInvestments = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);


  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Nos investissements | Repat Africa"
            description="Découvrez nos investissements pour l'Afrique. Découvrez les opportunités, les statistiques et notre feuille de route pour un développement économique durable."
            image={img}
            robots="index, follow"
            canonical={`https://www.repat.africa/${intl.locale}/investments`}
            keywords="investissements dynamiques, Afrique, développement économique, opportunités d'investissement, Repat Africa"
            type="website"
          />
          <InvestmentsHeroSection />
          <OurInvestments_tab />

          <Questions />
          <JoinCommunity />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default OurInvestments;
