import { Trans } from '@lingui/macro';
import { Link } from 'gatsby'
import React from 'react'


const AboutInvestments = () => {
    return (
        <section className='AboutInvestments_section'>
            <div className='container'>
            <div className='columns'>
            <div className='column Consolidation_details_responcive'>
                <div className='Consolidation_details'>
                <h3>About this investment</h3>
                <p>With the integration of smart contracts, the Goguen era represents a big step forwards in capability for the Cardano network. Where the Shelley era decentralizes the core of the system, Goguen adds the ability to build decentralized applications (DApps) on Cardano's solid foundation of peer-reviewed research and high-assurance development.</p>
                <p>The work for Goguen has been under way in parallel with Shelley, and when complete will allow users from technical and non-technical backgrounds to create and execute functional smart contracts on the Cardano network. One of the goals for the Goguen era has been the creation of Plutus, a purpose-built smart contract development language and execution platform using the functional programming language Haskell. Plutus is already available for testing and brings the benefits of functional programming to smart contract creation. It also allows one code base to support both on and off-chain components, improving the coherency and usability of the development experience compared with existing smart contract implementations.</p>
                <p>The Goguen era also encompasses work to make Cardano accessible to wider audiences via Marlowe, allowing financial and business experts with no previous technical knowledge to create smart contracts. Marlowe is a high-level, domain-specific language (DSL) for financial contracts which is built on Plutus. Marlowe comes with the Marlowe Playground, an easy-to-use application-building platform that non-programmers can use to build</p>
                <p>With the integration of smart contracts, the Goguen era represents a big step forwards in capability for the Cardano network. Where the Shelley era decentralizes the core of the system, Goguen adds the ability to build decentralized applications (DApps) on Cardano's solid foundation of peer-reviewed research and high-assurance development.</p>
                <p>The work for Goguen has been under way in parallel with Shelley, and when complete will allow users from technical and non-technical backgrounds to create and execute functional smart contracts on the Cardano network. One of the goals for the Goguen era has been the creation of Plutus, a purpose-built smart contract development language and execution platform using the functional programming language Haskell. Plutus is already available for testing and brings the benefits of functional programming to smart contract creation. It also allows one code base to support both on and off-chain components, improving the coherency and usability of the development experience compared with existing smart contract implementations.</p>
                <p>The Goguen era also encompasses work to make Cardano accessible to wider audiences via Marlowe, allowing financial and business experts with no previous technical knowledge to create smart contracts. Marlowe is a high-level, domain-specific language (DSL) for financial contracts which is built on Plutus. Marlowe comes with the Marlowe Playground, an easy-to-use application-building platform that non-programmers can use to build</p>
              </div>
                </div>
                <div className='column'>
                    <div className='AboutInvestments_Information'>
                        <div className='Information_heading'>
                            <h3>Information</h3>
                        </div>
                        <div className='Information_Box'>
                            <div className='Information_details'>
                                <p>Market capitalisation: </p>
                                <span>$435 billion</span>
                            </div>
                            <div className='Information_details'>
                                <p>Current price:</p>
                                <span>$166</span>
                            </div>
                            <div className='Information_details'>
                                <p>Amount invested:</p>
                                <span>$169000</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column Consolidation_details_desktop'>
                <div className='Consolidation_details'>
                <h3>About this investment</h3>
                <p>With the integration of smart contracts, the Goguen era represents a big step forwards in capability for the Cardano network. Where the Shelley era decentralizes the core of the system, Goguen adds the ability to build decentralized applications (DApps) on Cardano's solid foundation of peer-reviewed research and high-assurance development.</p>
                <p>The work for Goguen has been under way in parallel with Shelley, and when complete will allow users from technical and non-technical backgrounds to create and execute functional smart contracts on the Cardano network. One of the goals for the Goguen era has been the creation of Plutus, a purpose-built smart contract development language and execution platform using the functional programming language Haskell. Plutus is already available for testing and brings the benefits of functional programming to smart contract creation. It also allows one code base to support both on and off-chain components, improving the coherency and usability of the development experience compared with existing smart contract implementations.</p>
                <p>The Goguen era also encompasses work to make Cardano accessible to wider audiences via Marlowe, allowing financial and business experts with no previous technical knowledge to create smart contracts. Marlowe is a high-level, domain-specific language (DSL) for financial contracts which is built on Plutus. Marlowe comes with the Marlowe Playground, an easy-to-use application-building platform that non-programmers can use to build</p>
                <p>With the integration of smart contracts, the Goguen era represents a big step forwards in capability for the Cardano network. Where the Shelley era decentralizes the core of the system, Goguen adds the ability to build decentralized applications (DApps) on Cardano's solid foundation of peer-reviewed research and high-assurance development.</p>
                <p>The work for Goguen has been under way in parallel with Shelley, and when complete will allow users from technical and non-technical backgrounds to create and execute functional smart contracts on the Cardano network. One of the goals for the Goguen era has been the creation of Plutus, a purpose-built smart contract development language and execution platform using the functional programming language Haskell. Plutus is already available for testing and brings the benefits of functional programming to smart contract creation. It also allows one code base to support both on and off-chain components, improving the coherency and usability of the development experience compared with existing smart contract implementations.</p>
                <p>The Goguen era also encompasses work to make Cardano accessible to wider audiences via Marlowe, allowing financial and business experts with no previous technical knowledge to create smart contracts. Marlowe is a high-level, domain-specific language (DSL) for financial contracts which is built on Plutus. Marlowe comes with the Marlowe Playground, an easy-to-use application-building platform that non-programmers can use to build</p>
              </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default AboutInvestments