import React, { useState } from 'react';
import playButton from '../images/playButton.svg';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const VideoFacade = ({ videoTitle, videoImage, videoLink}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const loadVideo = () => {
    setIsVideoLoaded(true);
  };

  const image = videoImage ? getImage(videoImage.asset) : null;

  return (
    <div className="video-facade" style={{ width: '100%', maxWidth: '600px' }}>
      {isVideoLoaded ? (
        <div className="responsive-iframe-container">
          <iframe
            src={`${videoLink}?autoplay=1`}
            title={videoTitle}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        <div className="video-thumbnail" onClick={loadVideo} style={{ width: '100%', maxWidth: '600px' }}>
          {image ? (
            <GatsbyImage
              image={image}
              alt={videoTitle}
              className="video-thumbnail-image"
              style={{ width: '100%', height: 'auto' }}
            />
          ) : (
            <StaticImage
              src="../images/videoThumbnail.webp"
              alt="L'essor de la diaspora"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          <button className="play-button">
            <img
              src={playButton}
              alt="Play button"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoFacade;
