import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import i18n from '../i18n';
import { I18nProvider } from '@lingui/react';

import Layout from '../components/Layout.js';
import About from '../components/About.js';
import Seo from "../components/Seo.js";

import img from '../images/repat-about.png';


const AboutUs = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);


  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Qui sommes-nous ?"
            description="Découvrez Repat Africa, une organisation dédiée à promouvoir et concrétiser l'investissement en Afrique, en soutenant l'entrepreneuriat et le développement économique."
            image={img}
            robots="index, follow"
            canonical={`https://www.repat.africa/${intl.locale}/about`}
            keywords="Repat Africa, investissement en Afrique, entrepreneuriat, développement économique, diaspora africaine"
            type="website"
          />
          <About/>
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default AboutUs;
