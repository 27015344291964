import React, { useState } from 'react'
import '../styles/_questions.scss';
import { Trans } from '@lingui/macro';

const ExpansionWorkScope = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='RoadMapDetailsAgojieScope_section Question-section'>
      <div className='container'>
        <div className='RoadMapDetailsAgojieScope_Heading'>
          <h3><Trans>Objectifs de la phase Shaka Zulu</Trans></h3>
        </div>
        <div className='RoadMapDetailsAgojieScope_details'>
          <div className='columns'>
            <div className='column'>
              <div className="QuestionAccordion">
                <div className="accordion-wrapper">
                  <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="checkone" />
                    <label className="accordion-label" htmlFor="checkone"><Trans>Conquérir de nouveaux marchés</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Pour pénétrer et dominer de nouveaux marchés en Afrique, augmentant ainsi notre influence et notre portée</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="checktwo" />
                    <label className="accordion-label" htmlFor="checktwo"><Trans>Amplifier la réputation mondiale</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Lancer campagnes globales pour accroître la notoriété et la reconnaissance de Repat Africa sur la scène internationale.</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="checkthree" />
                    <label className="accordion-label" htmlFor="checkthree"><Trans>Forger des alliances globales</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Établir des partenariats stratégiques avec des organisations et des investisseurs internationaux pour soutenir et renforcer notre expansion.</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="checkfour" />
                    <label className="accordion-label" htmlFor="checkfour"><Trans>Accroître les investissements stratégiques</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Identifier et investir dans des opportunités clés à travers l’Afrique pour maximiser la croissance, l’impact économique et les retours sur investissement pour la communauté Repat Africa.</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='Consolidation_details'>
                <h3><Trans>Expansion</Trans></h3>
                <p className="has-text-justified">
                  <Trans>
                    Avec la phase d’expansion, la phase Shaka Zulu représente un grand pas en avant pour la communauté Repat Africa. Alors que la phase Agojie Dahomey a consolidé nos acquis, la phase Shaka Zulu se concentre sur l’extension de notre portée et l’augmentation de notre influence, en s’appuyant sur notre base solide de partenariats et de développement stratégique.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Le travail pour la phase Shaka Zulu se déroulera en parallèle avec d’autres initiatives, et une fois achevée, elle permettra à Repat Africa de pénétrer de nouveaux marchés et de renforcer sa présence globale. L’un des objectifs principaux de cette phase sera d’amplifier notre réputation mondiale et de forger des alliances stratégiques avec des acteurs clés de l’industrie.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous lancerons des initiatives et des campagnes globales pour accroître la notoriété et la reconnaissance de Repat Africa sur la scène internationale. Cela comprendra des efforts de marketing, des partenariats médiatiques, et la participation à des événements internationaux pour mettre en avant notre mission et nos réussites.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Établir des partenariats stratégiques avec des organisations et des investisseurs internationaux sera crucial pour soutenir notre expansion. Nous travaillerons à créer des alliances qui apporteront des ressources, des connaissances et des opportunités uniques, renforçant ainsi notre position sur le marché global.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous identifierons et investirons dans des opportunités clés à travers l’Afrique pour maximiser la croissance et l’impact économique. Cela inclura des projets innovants et des initiatives qui promettent des retours sur investissement substantiels pour la communauté Repat Africa, tout en soutenant le développement économique régional.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    En résumé, la phase Shaka Zulu se concentrera sur l’expansion stratégique de Repat Africa, en conquérant de nouveaux marchés, en amplifiant notre réputation mondiale, en forgeant des alliances globales et en accroissant les investissements stratégiques. Cela nous permettra de garantir une croissance durable et une influence accrue sur le continent africain et au-delà.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default ExpansionWorkScope;
