import React from 'react';
import Circleeffect from '../images/Circleeffect.png';
import { Trans } from '@lingui/macro';



const ConditionsBecomeMember = ({ locale }) => {
  return (
    <>
      <section className='ConditionsBecomeMember-section'>
        <div className='container'>
          <div className='columns alignItemCenter'>
            <div className='is-12-mobile is-12-tablet is-6-desktop column'>
              <div className='Conditions-heading'>
                <h3><Trans>Trois étapes</Trans></h3>
                <p><Trans>Afin de nous rejoindre et participer dès maintenant à la transformation de l'Afrique.</Trans></p>
                <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
              </div>
            </div>
            <div className='is-12-mobile is-12-tablet is-6-desktop column'>
              <div className='ConditionsList'>
                <div className='ConditionsListBox'>
                  <div className='ConditionsListnumber'>
                    <p>1</p>
                    <span>
                      <img src={Circleeffect} alt="" />
                    </span>
                  </div>
                  <div className='ConditionsListdetails'>
                    <h3><Trans>Regardez la présentation</Trans></h3>
                    <p><Trans>Nous avons conçu une vidéo complète de présentation de nos actions.</Trans></p>
                  </div>
                </div>
                <div className='ConditionsListBox'>
                  <div className='ConditionsListnumber'>
                    <p>2</p>
                    <span><img src={Circleeffect} alt="" /></span>
                  </div>
                  <div className='ConditionsListdetails'>
                    <h3><Trans>Candidatez</Trans></h3>
                    <p><Trans>Remplissez le formulaire de candidature qui vous prendra environ 5 minutes.</Trans></p>
                  </div>
                </div>
                <div className='ConditionsListBox mb-0'>
                  <div className='ConditionsListnumber'>
                    <p>3</p>
                    <span><img src={Circleeffect} alt="" /></span>
                  </div>
                  <div className='ConditionsListdetails'>
                    <h3><Trans>Ouvrez votre boite mail</Trans></h3>
                    <p><Trans>Sous 24h, vous recevrez un retour de notre part.</Trans></p>
                  </div>
                </div>
              </div>
              <a href='#' className='button-membership Qui-button ConditionsListButtonR'><Trans>Devenir membre</Trans></a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ConditionsBecomeMember;
