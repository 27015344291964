import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import i18n from '../i18n';
import { I18nProvider } from '@lingui/react';

import Layout from '../components/Layout';
import Seo from "../components/Seo";
import Membership from '../components/Membership';
import img from '../images/repatalk-12.webp'

const Join = ({ data }) => {

  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Seo
          lang={intl.locale}
          title="Rejoignez Repat Africa | Adhésion"
          description="Faites partie d'une communauté dynamique et ayez un impact direct sur les économies locales africaines. Devenez membre dès aujourd'hui."
          image={img}
          robots="index, follow"
          canonical={`https://www.repat.africa/${intl.locale}/join`}
          keywords="adhésion, Repat Africa, communauté, impact économique, Afrique, développement"
          type="website"
        />
        <Layout locale={intl.locale}>
          <Membership locale={intl.locale} />
        </Layout>

      </I18nProvider>
    </div>
  )
}

export default Join;
