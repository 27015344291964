import { Trans } from '@lingui/macro'
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'


const DiscoverBusinessInAfrica = () => {
  return (
    <div className='container DiscoverBusinessInAfrica-section padding-2'>
      <div className="columns is-vcentered is-centered">
        <div className="column is-5-desktop">
          <h1 className="title-home">
            <Trans>
              <span className="title-home title-home-africa">Séjour</span>
              <br className="br-desktop" />
              <span className="title-home title-home-is-calling">immersif&nbsp;</span>
              <br className="br-desktop" />
              <span className="title-home title-home-you">en Afrique</span>
            </Trans>
          </h1>
          <p className="text-home has-text-justified">
            <Trans>
              Le Repat Africa Tour est un voyage d’affaires <strong>immersif</strong> en Afrique. Une expérience <strong>incontournable</strong> qui vous permettra de découvrir les <strong>opportunités</strong> d’affaires mais également, les us et coutumes des pays visités. Participez à cette <strong>incroyable</strong> aventure pour comprendre le <strong>potentiel</strong> de l’Afrique.
            </Trans>
          </p>
          <div className="flex mt-6 membershipButton">
            <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
          </div>
        </div>

        <div className="column is-6-desktop pl-desktop-6">
          <StaticImage
            src="../images/repat-tour.webp"
            loading="eager"
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            alt="Membres au Repat Tour"
            className="radius-8 pl-desktop-6"
          />
        </div>
      </div>
    </div>
  )
}

export default DiscoverBusinessInAfrica
