import React, { useState } from 'react'
import { Trans } from '@lingui/macro';
import '../styles/_questions.scss';


const FundationWorkScope = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='RoadMapDetailsAgojieScope_section Question-section'>
      <div className='container'>
        <div className='RoadMapDetailsAgojieScope_Heading'>
          <h3><Trans>Objectifs de la phase Osei Tutu</Trans></h3>
        </div>
        <div className='RoadMapDetailsAgojieScope_details'>
          <div className='columns'>
            <div className='column'>
              <div className="QuestionAccordion">
                <div className="accordion-wrapper">
                  <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="checkone" />
                    <label className="accordion-label" htmlFor="checkone"><Trans>Définir la vision stratégique</Trans></label>
                    <div className="accordion-content">
                      <p>
                        <Trans>
                          Nous avons défini la mission et les objectifs à long terme de Repat Africa, établissant une feuille de route claire pour guider notre développement futur.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="checktwo" />
                    <label className="accordion-label" htmlFor="checktwo"><Trans>Établir des partenariats clés</Trans></label>
                    <div className="accordion-content">
                    <p>
                      <Trans>
                        Nous avons identifié et collaboré avec des partenaires stratégiques essentiels pour soutenir notre croissance et renforcer notre position sur le marché.
                      </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="checkthree" />
                    <label className="accordion-label" htmlFor="checkthree"><Trans>Développer les fonctionnalités initiales</Trans></label>
                    <div className="accordion-content">
                    <p>
                      <Trans>
                        Nous avons mis en œuvre les premières fonctionnalités de notre plateforme, assurant qu’elles répondent aux besoins essentiels de la communauté.
                      </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="checkfour" />
                    <label className="accordion-label" htmlFor="checkfour"><Trans>Mettre en place les structures organisationnelles</Trans></label>
                    <div className="accordion-content">
                    <p>
                      <Trans>
                        Nous avons créé les équipes et les processus internes nécessaires pour assurer une gestion opérationnelle efficace.
                      </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='Consolidation_details'>
                <h3><Trans>Fondation</Trans></h3>
                <p className="has-text-justified">
                  <Trans>
                    La phase Osei Tutu représente une étape essentielle pour poser les bases solides de Repat Africa. Alors que les phases suivantes se concentreront sur l’expansion et l’innovation, cette phase se focalise sur la mise en place des éléments fondamentaux nécessaires pour assurer la réussite future de notre communauté.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous avons commencé par définir clairement notre vision et nos objectifs à long terme. Cela signifie que nous avons établi une direction claire pour où nous voulons aller et comment nous comptons y parvenir. Cette vision guide toutes nos actions et décisions, en veillant à ce que chaque étape contribue à notre mission de promouvoir le retour et l’épanouissement des diasporas dans leur pays d’origine.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous avons également travaillé à établir des partenariats stratégiques avec d’autres organisations et individus qui partagent notre vision. Ces partenariats nous apportent un soutien précieux, des ressources et des opportunités qui nous aident à construire une communauté plus forte et plus connectée.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Une autre partie importante de cette phase a été de développer les premières fonctionnalités de notre plateforme. Cela comprend des outils et des services de base qui permettent à notre communauté de se connecter, de partager des ressources et de collaborer. Ces fonctionnalités sont conçues pour être faciles à utiliser, même pour ceux qui n’ont pas de compétences techniques.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Enfin, nous avons mis en place les équipes et les structures organisationnelles nécessaires pour gérer efficacement notre projet. Cela signifie que nous avons défini des rôles et des responsabilités clairs, et mis en place des processus pour nous assurer que tout fonctionne bien et que nous pouvons grandir de manière durable.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                  En résumé, la phase Osei Tutu est dédiée à la création d’une base solide pour Repat Africa. Nous avons défini notre vision, établi des partenariats clés, développé des outils essentiels et mis en place une organisation efficace. Tout cela est fait pour que notre communauté puisse prospérer et réaliser notre mission commune.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default FundationWorkScope;
