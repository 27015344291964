import React, { useEffect, useRef } from 'react';

import { Trans } from '@lingui/macro';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import IframeResizer from 'iframe-resizer-react';



const BecomeMemberTestimonials = ({ locale }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (!swiperRef.current) return;
    const swiper = new Swiper(swiperRef.current, {
      direction: "horizontal",
      modules: [Navigation],
      navigation: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 5000
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      effect: "fade",
    });

    return () => {
      swiper.destroy();
    };
  }, []);


  return (
    <>
      <section className='BecomeMemberTestimonials-section'>
        <div className='container'>
          <div className='columns alignItemCenter'>
            <div className='is-12-tablet is-12-desktop column'>
              <div className='BecomeMemberTestimonials_heading'>
                <h2><Trans>Témoignages</Trans></h2>
                <p className="mb-5"><Trans>Les membres Repat Africa partagent leur expérience</Trans></p>
                <a href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer" className='button-membership Qui-button ConditionsListButtonD BecomeMemberTestimonials_button'>Devenir membre</a>
              </div>
              <IframeResizer src="https://embed-v2.testimonial.to/w/expérience-repat?theme=light&card=base&loadMore=on&initialCount=8" style={{ width: "1px", minWidth: "100%" }} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BecomeMemberTestimonials;
