exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-details-js": () => import("./../../../src/pages/investment-details.js" /* webpackChunkName: "component---src-pages-investment-details-js" */),
  "component---src-pages-investments-js": () => import("./../../../src/pages/investments.js" /* webpackChunkName: "component---src-pages-investments-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-repat-africa-tour-js": () => import("./../../../src/pages/repat-africa-tour.js" /* webpackChunkName: "component---src-pages-repat-africa-tour-js" */),
  "component---src-pages-roadmap-agojiedahomey-js": () => import("./../../../src/pages/roadmap/agojiedahomey.js" /* webpackChunkName: "component---src-pages-roadmap-agojiedahomey-js" */),
  "component---src-pages-roadmap-index-js": () => import("./../../../src/pages/roadmap/index.js" /* webpackChunkName: "component---src-pages-roadmap-index-js" */),
  "component---src-pages-roadmap-mansamoussa-js": () => import("./../../../src/pages/roadmap/mansamoussa.js" /* webpackChunkName: "component---src-pages-roadmap-mansamoussa-js" */),
  "component---src-pages-roadmap-oseitutu-js": () => import("./../../../src/pages/roadmap/oseitutu.js" /* webpackChunkName: "component---src-pages-roadmap-oseitutu-js" */),
  "component---src-pages-roadmap-shakazulu-js": () => import("./../../../src/pages/roadmap/shakazulu.js" /* webpackChunkName: "component---src-pages-roadmap-shakazulu-js" */)
}

