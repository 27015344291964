import React from 'react'


const RoadMapHistory = () => {
    return (
        <section className='RoadMapHistory_Section'>
            {/* <div className='RoadMapHistory_video'>
                <img src={RoadMapHistoryImg} />
            </div> */}
            <div className='RoadMapHistory_details'>
                <div className='container'>
                    <div className='RoadMapHistory_heading'>
                        <h2>Enim minim veniam</h2>
                        <p>Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
                <div className='RoadMapHistory_buttonBox'>
                    <div className='RoadMapHistory_button'>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="244" height="244" viewBox="0 0 244 244" fill="none">
                                <g filter="url(#filter0_d_5884_15991)">
                                    <circle cx="122.002" cy="122.002" r="89.1538" fill="#FFCD7E" />
                                </g>
                                <path d="M165.922 120.269C167.255 121.039 167.255 122.963 165.922 123.733L101.537 160.906C100.204 161.676 98.5373 160.713 98.5373 159.174L98.5373 84.8287C98.5373 83.2891 100.204 82.3269 101.537 83.0967L165.922 120.269Z" fill="#1D2734" />
                                <path d="M244 122C244 189.379 189.379 244 122 244C54.6213 244 0 189.379 0 122C0 54.6213 54.6213 0 122 0C189.379 0 244 54.6213 244 122ZM7.91151 122C7.91151 185.009 58.9907 236.089 122 236.089C185.009 236.089 236.089 185.009 236.089 122C236.089 58.9907 185.009 7.91151 122 7.91151C58.9907 7.91151 7.91151 58.9907 7.91151 122Z" fill="url(#paint0_linear_5884_15991)" />
                                <defs>
                                    <filter id="filter0_d_5884_15991" x="22.8477" y="27.8477" width="198.309" height="198.309" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="5" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.933333 0 0 0 0 0.819225 0 0 0 0 0.801111 0 0 0 0.27 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5884_15991" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5884_15991" result="shape" />
                                    </filter>
                                    <linearGradient id="paint0_linear_5884_15991" x1="121.109" y1="-226.19" x2="123.008" y2="286.745" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.359375" stopColor="#FFCD7E" />
                                        <stop offset="0.867315" stopColor="#796362" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div className='RoadMapHistory_button_details'>
                        <h5>Our history</h5>
                        <p>Watch presentation 3:53 sec</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoadMapHistory
