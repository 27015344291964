import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import '../styles/_questions.scss';


const Questions = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='Question-section'>
      <div className='container'>
        <div className='app-haeding section-content feedbacks-heading'>
          <h2><Trans>Foire aux questions</Trans></h2>
          <p><Trans>Trouvez les réponses aux questions les plus récurrentes</Trans></p>
        </div>
        <div className="QuestionAccordion">
          <div className="accordion-wrapper">
            <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="check1" />
              <label className="accordion-label" htmlFor="check1"><Trans>🙋‍♂️🙋‍♀️ Qui sont les membres Repat Africa ?</Trans></label>
              <div className="accordion-content">
                <p>
                  <Trans>
                    Ils sont investisseurs ou futurs investisseurs via les investissements proposés par Repat Africa. Issus de divers milieux professionnels, ils sont du milieu de l’immobilier, de la tech, de l’enseignement, de l’agro-alimentaire, de l’art, de la finance, de la santé, de la logistique. Entrepreneurs, salariés et étudiants de 20 à 65 ans.
                    Avec ou sans projet personnel précis pour l’Afrique, tous ont un objectif commun : avoir un impact socio-économique en Afrique via l’investissement et l’entrepreneuriat avec Repat Africa.
                  </Trans>
                  </p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="check2" />
              <label className="accordion-label" htmlFor="check2"><Trans>✅ Quelles sont les conditions d’entrée ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Votre candidature à Repat Africa est soumise à une étude rigoureuse de notre équipe. Elle se déroule en plusieurs étapes. D'abord, vous devez remplir de manière détaillée le formulaire de candidature. Ensuite, si votre candidature est retenue, vous recevrez une réponse par mail dans un délai de 48 heures. Un entretien par visioconférence sera ensuite programmé avec un membre de notre équipe. À l'issue de cet entretien, une décision sera prise de manière conjointe avec le membre de l'équipe pour déterminer si vous rejoindrez notre communauté.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="check3" />
              <label className="accordion-label" htmlFor="check3"><Trans>💵 Quel est le montant de l'adhésion ? À quoi sert-elle ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>L'adhésion est de 500 EUR par an. Cette somme permet de soutenir la recherche et développement, la formation des membres, les partenariats, ainsi que l'organisation d'événements exclusifs.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="check4" />
              <label className="accordion-label" htmlFor="check4"><Trans>⌛️ Quand vais-je recevoir une réponse concernant ma candidature ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Dans les 48 heures après votre candidature.</Trans></p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[4].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(4)} name="checkbox-5" id="check5" />
              <label className="accordion-label" htmlFor="check5"><Trans>💳  Pourquoi faut-il ajouter ses informations bancaires avant la visioconférence ?</Trans></label>
              <div className="accordion-content">
                <p>
                  <Trans>
                    Remplir votre mandat de prélèvement avec vos informations bancaires est nécessaire pour compléter votre dossier de candidature. Une fois votre candidature acceptée, l'entretien par visioconférence réalisé, et votre accord donné, vous aurez immédiatement accès à la communauté et à tous les avantages de Repat Africa. Si votre candidature n'est finalement pas retenue, vos informations bancaires seront détruites.
                    Soyez rassuré, aucun prélèvement n'est effectué sans votre confirmation d'entrée dans la communauté, qui ne se fait qu'après l'entretien par visioconférence.
                    Notez que nous utilisons une plateforme sécurisée pour le traitement des mandats afin de protéger vos informations bancaires.
                  </Trans>
                </p>
              </div>
            </div>
            <div className={`accordion ${activeIndex[5].isActive ? "active-accordion" : 'inactive-accordion'}`}>
              <input type="checkbox" onChange={(e) => handleClick(5)} name="checkbox-6" id="check6" />
              <label className="accordion-label" htmlFor="check6"><Trans>❌  Pourquoi ma candidature n’a pas été retenue ?</Trans></label>
              <div className="accordion-content">
                <p><Trans>Nous comprenons que cette décision puisse être décevante pour vous. Plusieurs raisons peuvent expliquer cette décision. Vos objectifs peuvent ne pas être en alignement avec nos objectifs d’investissement communs concrets. Malgré tout, nous vous encourageons à continuer à explorer d'autres opportunités et à poursuivre vos efforts pour atteindre vos objectifs en Afrique.</Trans></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Questions;
