import { Trans } from '@lingui/macro';
import { Link } from 'gatsby'
import React from 'react'


const OurInvestmentsStatistic = () => {
    return (
        <section className='OurInvestmentsStatistic_Section'>
            <div className='container'>
                <div className='OurInvestmentsStatistic_heading'>
                    <h2>Statistic</h2>
                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <div className='columns'>
                    <div className='is-12-tablet is-6-desktop column '>
                        <div className='wasBox'>
                            <div className='wasBox_heading'>
                                <h2>Was</h2>
                            </div>
                            <div className='wasdetails'>
                                <div className='wasdetails_roundProgress'>
                                    <div className='round_progressBar'>
                                        <div className='greenProgressbar'>
                                            <div className='progressDetails'>
                                                <h2>70%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar pinkProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>50%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar redProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>60%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar yellowProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>70%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wasdetails_horizontalProgress'>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='is-12-tablet is-6-desktop column '>
                    <div className='wasBox Became'>
                            <div className='wasBox_heading'>
                                <h2>Became</h2>
                            </div>
                            <div className='wasdetails'>
                                <div className='wasdetails_roundProgress'>
                                    <div className='round_progressBar'>
                                        <div className='greenProgressbar'>
                                            <div className='progressDetails'>
                                                <h2>70%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar pinkProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>50%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar redProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>60%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                        <div className='greenProgressbar yellowProgreeBar'>
                                            <div className='progressDetails'>
                                                <h2>70%</h2>
                                                <p>Distribution</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wasdetails_horizontalProgress'>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                    <div className='capitalisation'>
                                        <label>Market capitalisation: </label>
                                        <progress class="progress is-warning" value="75" max="100"> 75% </progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurInvestmentsStatistic;