
import React from 'react'
import { Trans } from '@lingui/macro'
import { StaticImage } from 'gatsby-plugin-image';

const WhatisAfricaTour = () => {
  return (
    <section className='WhatisAfricaTour-section'>
      <div className='container padding-2'>
        <div className="columns align-center is-vcentered">
          <div className="is-12-mobile is-6-tablet is-6-dekstop column">
            <div className='WhatisAfricaTourImg'>
              <StaticImage
                src="../images/mariamejeufzone.webp"
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="Membres au Repat Tour"
                className="radius-8"
              />
            </div>
          </div>

          <div className="is-12-mobile is-6-tablet is-6-dekstop column">
            <div className='WhatisAfricaTour-details'>
              <h3>
                <Trans>Qu'est-ce que le Repat Africa Tour ?</Trans>
              </h3>
              <p>
                <Trans>
                  Le Repat Africa Tour est un voyage <strong>immersif</strong> de 10 à 15 jours conçu pour les membres de Repat Africa désireux de découvrir les dynamiques économiques et culturelles de l'Afrique. Ce voyage, organisé <strong>régulièrement</strong>, offre une série de <strong>rencontres professionnelles</strong> ciblées avec des institutions clés, des entrepreneurs locaux influents, et d'autres repats ayant réussi leur intégration en Afrique.
                </Trans>
              </p>
              <p>
                <Trans>
                  Outre l'aspect professionnel, le tour intègre également des activités culturelles et touristiques, permettant aux participants de s'immerger dans la <strong>diversité</strong> et la <strong>richesse</strong> de l'Afrique.
                </Trans>
              </p>
              <p>
                <Trans>
                L'objectif principal est de dévoiler des <strong>opportunités d'investissement</strong> inédites, de tisser des liens précieux et d'assurer une visibilité médiatique considérable pour chaque participant. Ce voyage représente une occasion <strong>exceptionnelle</strong> de voir l'Afrique sous un angle nouveau et de concrétiser des investissements.
                </Trans>
              </p>
            </div>
          </div>

        </div>
      </div>
      <div className='container'>
        <div className='columns'>
          <div className='is-6-dekstop column'>
            <div className='discoverImg'>
              <div className='discoverImgFirstimg'>
                <StaticImage
                  src="../images/feujzne.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={315}
                  height={404}
                />


              </div>
              <div className='discoverImgmultiimg'>
                <StaticImage
                  src="../images/jeuefzone-665.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={292}
                  height={176}
                />

                <StaticImage
                  src="../images/mariam-jeuf-zone.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={292}
                  height={176}
                />

              </div>
            </div>
          </div>
          <div className='is-6-dekstop column WhatisAfricaTour-secondimg'>
            <div className='discoverImg'>
              <div className='discoverImgFirstimg'>
                <StaticImage
                  src="../images/oumou-jeuf-zone.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={267}
                  height={524}
                />
              </div>
              <div className='discoverImgmultiimg'>

                <StaticImage
                  src="../images/mariame-seye.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={370}
                  height={475}
                />
                <StaticImage
                  src="../images/plat-jeufzone.webp"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  alt="Membres au Repat Tour"
                  className="radius-8"
                  width={314}
                  height={189}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatisAfricaTour
