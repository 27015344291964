import { i18n } from "@lingui/core";
import { messages as frMessages } from "./locales/fr/messages";
import { messages as enMessages } from "./locales/en/messages";

i18n.load({
  fr: frMessages,
  en: enMessages
});

export default i18n;
