import { Trans } from '@lingui/macro';
import { Link } from 'gatsby'
import React from 'react'


const InvestmentsDetailsHeroSection = () => {
    return (
        <section className='InvestmentsDetailsHero_Section'>
            <div className='container'>
                <div className='InvestmentsDetailsHero_Heading'>
                    <h2>Kemet  & <span>Johnson</span></h2>
                    <p>Excepteur sint occaecat cupidatat non proident. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                    <div className="membershipButton">
                        <a className="button-membership" href="#" id="home-btn-member"><Trans>Devenir membre</Trans></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InvestmentsDetailsHeroSection
