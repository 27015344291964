import React from 'react';
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import Kararui from '../images/karai.webp';

const InvestmentsHeroSection = () => {
  return (
    <div className="container home-container InvestmentsHero_section">
        <div className="columns is-vcentered is-centered">
          <div className="column is-5-desktop">
            <h1 className="title-home lh-40 fw-800">
              <span className="title-home title-home-africa"><Trans>Nos</Trans>&nbsp;</span>
              <span className="title-home title-home-is-calling"><Trans>levées</Trans></span>
              <br className="br-desktop" />
              <span className="title-home title-home-you"><Trans>de fonds</Trans>&nbsp;</span>
            </h1>

            <p className="text-home has-text-justified">
              <Trans>
                Nous proposons des <strong>investissements</strong> dans les startups et PME africaines les plus prometteuses. Chaque membre contribue au développement économique du continent en investissant dans <strong>l'innovation</strong>. L'objectif est de soutenir les entrepreneurs africains résolvant des <strong>problèmes locaux</strong> tout en offrant des <strong>retours</strong> sur investissement. Nous ciblons des entreprises à fort potentiel de <strong>croissance</strong> qui peuvent impacter positivement le développement socio-économique de l'Afrique.
              </Trans>
            </p>
            <div className="flex mt-6 membershipButton">
              <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
            </div>
          </div>

          <div className="column is-6-desktop pl-desktop-6">
            <StaticImage
              src="../images/kara-rui.webp"
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="Membres au Repat Tour"
              className="radius-8"
              width={592}
              height={362}
            />

          </div>

        </div>
      </div>
  )
}

export default InvestmentsHeroSection
