import React, { useEffect } from 'react';
import i18n from '../i18n.js';
import { I18nProvider } from '@lingui/react';
import Seo from "../components/Seo.js";
import Layout from '../components/Layout.js';
import DiscoverBusinessInAfrica from '../components/DiscoverBusinessInAfrica.js';
import WhatisAfricaTour from '../components/WhatisAfricaTour.js';
import GoalsMissionTour from '../components/GoalsMissionTour.js';
import LastTour from '../components/LastTour.js';
import { useIntl } from 'gatsby-plugin-intl';
import img from "../images/repat-tour.webp"
import ParticipateTour from '../components/participateTour.js';
import Questions from '../components/Questions';
import JoinCommunity from '../components/JoinCommunity';

const RepatAfricaTour = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Repat Africa Tour : Opportunités d'affaires en Afrique."
            description="Participez au Repat Africa Tour, un voyage immersif qui explore les opportunités d'investissement, la richesse culturelle et le tourisme en Afrique."
            image={img}
            robots="index, follow"
            canonical={`https://www.repat.africa/${intl.locale}/repat-africa-tour`}
            keywords="Repat Africa Tour, opportunités d'affaires, investissement en Afrique, tourisme, culture africaine, développement économique, séjour immersif"
            type="website"
          />
          <DiscoverBusinessInAfrica />
          <WhatisAfricaTour />
          <GoalsMissionTour />
          <LastTour />
          <ParticipateTour />
          <Questions />
          <JoinCommunity />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default RepatAfricaTour;
