/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from 'react-helmet'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./Navbar";
import Footer from './Footer';
import favicon from '../images/favicon.png'

import "../styles/styles.scss";
import 'moment/locale/fr';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/swiper-bundle.css';
import { register } from 'swiper/element/bundle';
register();


const Layout = ({ children, locale }) => {

  // useEffect(() => {
  //   if (process.env.GATSBY_ENVIRONMENT === "production") {
  //     window.$crisp = [];
  //     window.CRISP_WEBSITE_ID = process.env.GATSBY_CRISP_ID;

  //     (function () {
  //       var d = document;
  //       var s = d.createElement("script");

  //       s.src = "https://client.crisp.chat/l.js";
  //       s.async = 1;
  //       d.getElementsByTagName("head")[0].appendChild(s);
  //     })();

  //   }
  // })


  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Navbar locale={locale} />
      <main>{children}</main>
      <Footer locale={locale} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
