import React from 'react';
import { Trans } from '@lingui/macro';
import '../styles/_join_community.scss';
import { StaticImage } from 'gatsby-plugin-image';


const JoinCommunity = () => {
  return (
    <section className='community-section'>
      <div className='container'>
        <div className='columns align-center'>
          <div className='column '>
            <div className='communityImgs'>
              <div className='community_img_one'>
                < StaticImage
                  src='../images/communityone.png'
                  title="Image 1 de l'application de Repat Africa"
                  alt="Communauté 1"
                />
              </div>
              <div className='community_img_two'>
                < StaticImage
                    src='../images/communitytwo.png'
                    title="Image 2 de l'application de Repat Africa"
                    alt="Communauté 2"
                />
              </div>
            </div>
          </div>
          <div className='column '>
            <div className='community'>
              <div className='community-details'>
                <div className='downlordApp'>
                  <div className='app-haeding'>
                    <h4><Trans>Bientôt disponible</Trans></h4>
                    <p><Trans>Participez au développement socio-économique de l'Afrique dès maintenant</Trans></p>
                  </div>
                  <div className='appButton'>

                    <button>
                        <StaticImage
                          src='../images/AppStore.png'
                          title="Téléchargez l'app de Repat Africa sur l'App store"
                          alt="App Store"
                        />
                      </button>
                    <button>
                      <StaticImage
                        src='../images/GooglePlay.png'
                        title="Téléchargez l'app de Repat Africa sur Google play"
                        alt="Google Play"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </section>
  )
}

export default JoinCommunity
