import React from 'react';
import { Link } from 'gatsby';
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = ({ locale }) => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <div className="footer-logo-img">
              <StaticImage
                src="../images/logo-long.webp"
                alt="Logo Repat Africa"
                className="footer-logo-img"
                title="Logo de Repat Africa"
              />
            </div>
            <div className="footer-logo-text">
              <p>
                <Trans>
                  Repat Africa : Mobiliser la diaspora africaine. Investir, entreprendre et s'établir en Afrique.
                </Trans>
              </p>
            </div>
          </div>

          <div className="footer-menu">
            <div className="footer-menu-item">
              <a href="https://blog.repat.africa" target="_blank" rel="noopener noreferrer" aria-label="Visitez le blog Repat Africa">Blog</a>
              <a href="https://repat.notion.site/Communiqu-de-Presse-2c97d3f5e47d44bf9ed7900a86fef457" target="_blank" rel="noopener noreferrer" aria-label="Notre dossier presse"><Trans>Presse</Trans></a>
              <a href="https://repatafrica.typeform.com/to/Znp6Ni1m" target="_blank" rel="noopener noreferrer" aria-label="Notre dossier presse"><Trans>Envoyez votre deck</Trans></a>
            </div>
            <div className="vertical-line"></div>
            <div className="footer-menu-item">
              <Link to={`/${locale}/about`}><Trans>À propos de Repat Africa</Trans></Link>
              <Link to={`/${locale}/legal`}><Trans>Mentions légales</Trans></Link>
              <a href="https://form.jotform.com/231196927961366" target="_blank" rel="noopener noreferrer" aria-label="Participez à l'étude de Repat Africa sur les diasporas africaines"><Trans>Participez à notre étude</Trans></a>
            </div>
            <div className="vertical-line"></div>
            <div className="footer-menu-item">

              <a href="https://www.youtube.com/@repatafrica" target="_blank" rel="noopener noreferrer" aria-label="Replays de notre chaine YouTube"><Trans>Replays</Trans></a>
            </div>
          </div>

          <div className="footer-social">
            <p><Trans>Retrouvez-nous :</Trans></p>
            <div className="footer-social-icons">
              <a href="https://www.instagram.com/repat.africa"
                target="_blank" rel="noopener noreferrer"
                aria-label="Visitez le compte Instagram de Repat Africa"
                title="Suivez Repat Africa sur Instagram"
              >
                <StaticImage src="../images/instagram.svg" alt="Logo Instagram de Repat Africa" />
              </a>
              <a href="https://www.linkedin.com/company/repat-africa"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez la page LinkedIn de Repat Africa"
                title="Suivez Repat Africa sur LinkedIn"
              >
                <StaticImage src="../images/linkedin.svg" alt="Logo Linkedin de Repat Africa" />
              </a>
              <a href="https://www.facebook.com/repat.africa"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez la page Facebook de Repat Africa"
                title="Suivez Repat Africa sur Facebook"
              >
                <StaticImage src="../images/facebook.svg" alt="Logo Facebook de Repat Africa" />
              </a>
              <a href="https://twitter.com/repat_africa"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez le compte Twitter de Repat Africa"
                title="Suivez Repat Africa sur Twitter"
              >
                <StaticImage src="../images/twitter.svg" alt="Logo Twitter de Repat Africa" />
              </a>
              <a href="https://www.tiktok.com/@repat.africa"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visitez le compte TikTok de Repat Africa"
                title="Suivez Repat Africa sur TikTok"
              >
                <StaticImage src="../images/tiktok.svg" alt="Logo TikTok de Repat Africa" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
