import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import VideoFacade from './VideoFacade';
import moment from 'moment/moment';



const ArchiveEvents = ({ event }) => {
  const [activeTab, setActiveTab] = useState(event[0]?.id || null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const activeEvent = event?.find((eve) => eve?.id === activeTab);
  console.log("active event")
  console.log(activeEvent)

  return (
    <section className='ArchiveEvents-section'>
      <div className='container is-centered'>
        <h3><Trans>Évènements passés</Trans></h3>
        <div className='columns ArchiveEvents-tab'>

          <div className='is-12-tablet is-3-desktop column ArchiveEvent-list '>
            <div className='ArchiveEvents-tablist'>

              <div className="tabs is-boxed">
                <ul className="tabsList event-tabsList ArchiveEvents-tabsList">
                  {event?.length > 0 && event?.map((eve) => {
                    return (
                      <li key={eve.id} className={activeTab === eve.id ? 'is-active' : ''}>
                        <a onClick={() => setActiveTab(eve.id)}>
                          {eve.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='column'>
            {activeEvent && (
              <div className="tab-content event-tab-contant ArchiveEvents-tab-contant">
                <div className='ArchiveEvents-tab-contant-details'>
                  <p className='ArchiveEvents-tab-date'>{moment(activeEvent.startAt).format(('DD MMMM YYYY'))}</p>
                  <div>
                    <h4 className='tab-date-title'>{activeEvent.name}</h4>
                    <div className='tab-date-information'>
                      <div className='archiveEvents'>
                      <p className="has-text-justified">
                        {activeEvent.description.split(/(\?|!)/).map((sentence, index, array) => (
                          <React.Fragment key={index}>
                            {sentence.trim() + ((index + 1) % 2 === 0 ? '' : (sentence === '?' || sentence === '!' ? sentence : ''))}
                            {((index + 1) % 2 === 0 && index !== array.length - 1) && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                    <VideoFacade
                      videoLink={activeEvent.replay}
                      videoTitle="L'Essor de la Diaspora"
                      videoWidth={400}
                      videoHeight={"auto"}
                      videoImage={activeEvent.image}
                    />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArchiveEvents
