
import React from 'react'


const DynamicInvestmentsPlan = () => {
  return (
    <section className='DynamicInvestmentsPlan_section'>
                <div className='container'>
                 <div className='columns'>
                 <div className='column FuturePlansIndustry_responcive'>
                    <div className='FuturePlansIndustry'>
                      <div className='FuturePlansIndustry_heading'>
                        <h2>Future plans for industry</h2>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <p>Lorem ipsum dolor sit amet consectetur. Pellentesque molestie ipsum sed amet massa ac. Pharetra in cras urna at mi sit eu. Ipsum venenatis sit sodales duis ut lacus ac.</p>
                        <ul>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                        </ul>

                      </div>
                    </div>
                  </div>
                  <div className='column'>
                    <div className='InvestmentsDetailsHero_img'>

                    </div>
                  </div>
                  <div className='column'>
                    <div className='FuturePlansIndustry FuturePlansIndustry_desktop'>
                      <div className='FuturePlansIndustry_heading'>
                        <h2>Future plans for industry</h2>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                        <p>Lorem ipsum dolor sit amet consectetur. Pellentesque molestie ipsum sed amet massa ac. Pharetra in cras urna at mi sit eu. Ipsum venenatis sit sodales duis ut lacus ac.</p>
                        <ul>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                          <li>Ut enim ad minim veniam nostrud exercitation</li>
                        </ul>
                        <a class="button-membership" href="#">Become a member</a>
                      </div>
                    </div>
                  </div>
                 </div>
                 </div>
             </section>
  )
}

export default DynamicInvestmentsPlan;
