import React, { useEffect } from 'react';
import i18n from '../../i18n.js';
import { I18nProvider } from '@lingui/react';
import Seo from "../../components/Seo.js";
import Layout from '../../components/Layout.js';
import { useIntl } from 'gatsby-plugin-intl';
import img from '../../images/repat-about.png';
import RoadmapHeroSection from'../../components/RoadmapHeroSection.js';
import Questions from '../../components/Questions.js';
import JoinCommunity from '../../components/JoinCommunity.js';
import GovernanceWorkScope from '../../components/GovernanceWorkScope.js';


const MansaMoussa = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Qui sommes-nous ?"
            description="Repat Africa est une organisation non gouvernementale (ONG) d'intérêt général engagée à promouvoir et concrétiser l'investissement en Afrique."
            image={img}
            robots="index"
          />
          <RoadmapHeroSection locale={intl.locale}/>
          <GovernanceWorkScope />
          <Questions />
          <JoinCommunity />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default MansaMoussa;
