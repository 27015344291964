import React from 'react'
import { Trans } from '@lingui/macro';
import IframeResizer from 'iframe-resizer-react';


const Feedbacks = () => {
  return (
    <section className="section feedback-section">
      <div className="container">
        <div className="section-content feedbacks-heading">
          <h2><Trans>Témoignages</Trans></h2>
          <p><Trans>Les membres Repat  Africa partagent leur expérience</Trans></p>
        </div>
        <div className='columns is-desktop Feedbacks-flex'>
          <IframeResizer src="https://embed-v2.testimonial.to/w/expérience-repat?theme=light&card=base&loadMore=on&initialCount=8" style={{ width: "1px", minWidth: "100%" }} />
        </div>
      </div>
    </section>
  )
}

export default Feedbacks
