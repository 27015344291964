import React from 'react'
import { Trans } from '@lingui/macro';
import legalImg from '../images/legalImg.png';

const LegalMentions = () => {
  return (
    <>
      {/* <section className="section padded-section">
        <h1 className="title is-3 has-text-weight-bold has-text-black mb-5rem legal_heading">
          <Trans>Mentions <span className="title-underline">légales</span>
          </Trans>
        </h1>

        <div className="mb-6">
          <h2 className="subtitle is-4">Repat Africa</h2>
          <p>
            Association loi 1901 <br />
            66 avenue des Champs-Elysées <br />
            75008 Paris<br />
            SIRET : 885 162 917 00011
          </p>
          <br />
          <p>
            <Trans> Directeur de la publication :</Trans>
            Kara DIABY <br />
            E-mail: hello[at]repat.africa
          </p>
        </div>

        <div>
          <h2 className="mb-4 subtitle is-4"><Trans>Propriété intellectuelle</Trans></h2>
          <p>
            <Trans>
              Le site https://www.repat.africa est protégé par les dispositions du Code de la propriété intellectuelle, notamment par celles de ses dispositions relatives à la propriété littéraire et artistique, aux droits d'auteur et à la protection des bases de données.
            </Trans>
            <br /><br />

            <Trans>
              Ces droits sont la propriété exclusive de REPAT AFRICA. Le Code de la propriété intellectuelle n'autorise aux termes de l'article L.133-5, d'une part, que les copies ou reproductoins strictement réservées à l'usage privé du copiste et non destinées à une utilisation collective et, d'autre part, que les analyses eet les courtes citations dans un but d'exemple et d'illustration.
            </Trans>
            <br /><br />

            <Trans>
              De même, l'article L.342-3 du Code de la propriété intellectuelle n'autorise que l'extraction ou la réutilisation d'une partie non substantielle du contenu d'une base de donnée mise à la disposition du public par son titulaire. Par conséquent, toute représentation ou reproduction non autorisée des sites de REPAT AFRICA, par quelque moyen que ce soit, est strictement interdite sous peine de poursuites judiciaires. Le contrevenant s'expose à des sanctions civiles et pénales et notamment aux peines prévues aux articles L.335-2 et L.343-1 du Code de la propriété intellectuelle.
            </Trans>
          </p>
        </div>
        <div>
          <h2 className="mt-4 subtitle is-4"><Trans>Liens hypertextes</Trans></h2>
          <p>
            <Trans>
              Bien que nous apportons la plus grande rigueur dans la sélections des liens que nous proposons vers d'autres sites du réseau internet, REPAT AFRICA ne saurait être tenu responsable du contenu présent sur ces sites.
            </Trans>
          </p>
        </div>
      </section> */}
      <section className='LegalMentions-section'>
        <div className='container'>
          <div className='columns align-center'>
            <div className='is-12-mobile is-12-tablet is-5-desktop column'>
              <h1 className="title-home lh-40 fw-800 legalHeading">
                <Trans>
                  <span className="title-home-africa">Legal</span>
                  <br className="br-desktop" />
                  <span className="title-home-is-calling">Notice&nbsp;</span>
                </Trans>
              </h1>
              {/* <img src={img2} id="repat-home-mobile-img" alt="" /> */}
              <p className="text-home text-eventpage">
                <Trans>
                  Lorem ipsum dolor sit amet consectetur. Tellus tortor mauris aliquam non. Quis aliquam cursus vel aliquam nibh quis laoreet magna. Egestas purus sed arcu sit enim leo. In semper arcu integer eu tellus vel.
                </Trans>
              </p>
            </div>
            <div className='is-12-mobile is-12-tablet is-7-desktop column '>
              <div className='legal-img'>
                <img src={legalImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default LegalMentions;
