import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby'

const HomePageEvents = ({locale}) => {
  const [activeTab, setActiveTab] = useState('Pictures');

  return (
    <>
      <section className="section page-container event-section" id="event-page-container">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-half">
              <div className="section-content ">
                <h2><Trans>Évènements</Trans></h2>
                <p><Trans>Nous organisons des événements de haute qualité qui stimulent l'engagement et favorisent les échanges constructifs.</Trans></p>
              </div>
            </div>
          </div>
          <div className="eventTabs">
            <div className="columns is-desktop">
              <div className="column is-narrow-desktop">
                <div className="tabs is-boxed">
                  <ul className="tabsList">
                    <li className={activeTab === 'Pictures' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Pictures')}>
                        <div className="eventTabList">
                          <div className="tabList-image">
                            <StaticImage
                              src='../images/EventTabOne.jpg'
                              title="Taoficatou pitche sa startup"
                              alt="Taoficatou"
                              width={150}
                              formats={["auto", "webp", "avif"]}
                              placeholder="blurred"
                            />
                          </div>
                          <div className="tablist-details">
                            <h5><Trans>Pitch Night</Trans></h5>
                            <p><Trans>Découvrez des projets innovants en Afrique</Trans></p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className={activeTab === 'Music' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Music')}>
                        <div className="eventTabList">
                          <div className="tabList-image">
                            <StaticImage
                              src='../images/stanislaszeze.webp'
                              title="Stanislas Zeze en RepaTalk"
                              alt="Stanislas Zeze"
                              width={150}
                              formats={["auto", "webp", "avif"]}
                              placeholder="blurred"
                            />
                          </div>
                          <div className="tablist-details">
                            <h5><Trans>Talks</Trans></h5>
                            <p><Trans>Participez à des échanges profonds et sans filtre</Trans></p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className={activeTab === 'Videos' ? 'is-active' : ''}>
                      <a onClick={() => setActiveTab('Videos')}>
                        <div className="eventTabList">
                          <div className="tabList-image">
                            <StaticImage
                              src='../images/EventTabThree.webp'
                              title="Kara de Repat Africa et Rui Mendes Da Silva de Kemet Automotive"
                              alt="Kara et Rui"
                              width={150}
                              formats={["auto", "webp", "avif"]}
                              placeholder="blurred"
                            />
                          </div>
                          <div className="tablist-details">
                            <h5><Trans>Networking Night</Trans></h5>
                            <p><Trans>Développez votre réseau en une soirée</Trans></p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="tab-content">
                  <div className={`tab-pane ${activeTab === 'Pictures' ? 'is-active' : ''}`} id="Pictures">
                    <div className="eventtabContent">
                      <div className="eventtabContent-details">
                        <h2><Trans>Pitcher votre entreprise</Trans></h2>
                        <p><Trans>Une expérience destinée aux entrepreneurs désireux de présenter leur entreprise aux investisseurs de Repat Africa.</Trans></p>
                        <a href="https://repatafrica.typeform.com/to/Znp6Ni1m" target="_blank" rel="noopener noreferrer" aria-label="Notre dossier presse" className="see-more-btn"><Trans>Postuler au prochain pitch</Trans></a>
                      </div>
                      <div className="eventtabContent-img">
                        <StaticImage
                          src='../images/EventPitchNight.jpg'
                          title="Jury du Repat Pitch Night 2"
                          alt="Hawa et Salamata"
                          width={400}
                          formats={["auto", "webp", "avif"]}
                          placeholder="blurred"
                          className="radius-8"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'Music' ? 'is-active' : ''}`} id="Music">
                    <div className="eventtabContent">
                      <div className="eventtabContent-details">
                        <h2><Trans>Rencontrez des leaders africains</Trans></h2>
                        <p><Trans>Les membres Repat Africa ont le privilège de participer à des discussions et prendre contact avec des leaders africains.</Trans></p>
                        <Link to={`/${locale}/events`}  className="see-more-btn"><Trans>Voir plus</Trans></Link>
                      </div>
                      <div className="eventtabContent-img">
                        <StaticImage
                          src='../images/EventTalk.jpg'
                          title="Repat Talk avec Stanislas Zeze"
                          alt="Stan Zeze"
                          width={300}
                          formats={["auto", "webp", "avif"]}
                          placeholder="blurred"
                          className="radius-8"
                        />

                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'Videos' ? 'is-active' : ''}`} id="Videos">
                    <div className="eventtabContent">
                      <div className="eventtabContent-details">
                        <h2><Trans>Développez votre réseau</Trans></h2>
                        <p><Trans>Une soirée conçue pour les membres désirant développer leur réseau d'affaires.</Trans></p>
                        <Link to={`/${locale}/events`}  className="see-more-btn"><Trans>Voir plus</Trans></Link>
                      </div>
                      <div className="eventtabContent-img">
                        <StaticImage
                          src='../images/EventDetailOne.webp'
                          title="Membres Repat Africa"
                          alt="Les membres"
                          width={400}
                          formats={["auto", "webp", "avif"]}
                          placeholder="blurred"
                          className="radius-8"
                        />

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default HomePageEvents;
