import React from 'react'
import { Trans } from '@lingui/macro';
import AfricaDetails1 from '../images/AfricaDetails1.svg';
import AfricaDetails2 from '../images/AfricaDetails2.svg';
import AfricaDetails3 from '../images/AfricaDetails3.svg';
import AfricaDetails4 from '../images/AfricaDetails4.svg';
import Kara from '../images/TeamMember/kara.png';
import Theadventurebegins from '../images/Theadventurebegins.png';

const FounderWord = () => {


  return (
    <section className='founderWord-section'>
      <div className='container'>
        <div className='founderWord-heading'>
          <h2><Trans>Mot du fondateur</Trans></h2>
        </div>
        <div className='columns'>
          <div className='column is-half'>
              <div className='founderWord-slider'>
                <div className='user-details'>
                  <p className="has-text-justified"><Trans>En 3 ans et demi seulement, nous avons réussi a générer des emplois et avoir un réel impact socio-économique en Afrique. Nous pouvons être fiers de notre parcours remarquable et des résultats tangibles obtenus. Continuons à innover pour assurer un avenir prospère à l'Afrique.</Trans></p>
                  <div className='usersprofile'>
                    <div className='usersImg'>
                      <img src={Kara} alt='img' loading="lazy"/>
                    </div>
                    <div className='usersName'>
                      <h4>Kara Diaby</h4>
                      <p><Trans>Fondateur</Trans></p>
                    </div>
                  </div>
                </div>
              </div>

            <div className='Theadventurebegins-img'>
              <img src={Theadventurebegins} alt='img' loading="lazy" />
            </div>
          </div>
          <div className='column is-half'>
            <div className='WhyRepatAfrica'>
              <div className='WhyRepatAfricaHeading'>
                <h3><Trans>Pourquoi Repat Africa ?</Trans></h3>
              </div>
              <div className='WhyRepatAfricaDetails'>
                <div className='WhyRepatAfricaDetails-box'>
                  <img src={AfricaDetails1} alt='img' loading="lazy"/>
                  <p className="has-text-justified"><Trans>Notre objectif principal est de créer des emplois et de générer de la richesse sur le continent africain.</Trans></p>
                </div>
                <div className='WhyRepatAfricaDetails-box'>
                  <img src={AfricaDetails2} alt='img' loading="lazy"/>
                  <p className="has-text-justified">
                    <Trans>
                      Depuis 2019, la diaspora africaine est devenue la première source de financement du continent.
                      En 2022, 100 milliards d'euros ont été envoyés par les diasporas en Afrique dont 90% ont été utilisés pour l’aide familiale.
                    </Trans>
                  </p>
                </div>
                <div className='WhyRepatAfricaDetails-box'>
                  <img src={AfricaDetails3} alt='img' loading="lazy"/>
                  <p className="has-text-justified"><Trans>Nous visons à diriger les fonds des diasporas vers des investissements productifs et durables.</Trans></p>
                </div>
                <div className='WhyRepatAfricaDetails-box'>
                  <img src={AfricaDetails4} alt='img' loading="lazy"/>
                  <p className="has-text-justified">
                    <Trans>
                      Face à ce besoin, nous avons établi un cadre de confiance qui garantit la transparence et la fiabilité des investissements. Nous encourageons les diasporas à s'engager pleinement dans le développement économique de l'Afrique avec des contributions à impacts durables. Nous renforcçons également le sentiment de communauté et d'entraide en créant un réseau soudé qui soutient le progrès collectif.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className='Theadventurebegins-imgRes'>
                <img src={Theadventurebegins} alt='img' loading="lazy"/>
              </div>
              <div className='started_button'>
                <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FounderWord
