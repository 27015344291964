import React from 'react';
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';


const ImpactAfrica = ({ locale }) => {
  return (
    <div className='container padding-2 become-memberPage'>
      <div className="columns is-vcentered is-centered">
        <div className="column is-5-desktop">
          <h1 className="title-home lh-40 fw-800">
            <Trans>
              <span className="title-home title-home-africa">
                <Trans>Faire</Trans>
              </span>
              <br className="br-desktop" />
              <span className="title-home title-home-is-calling">
                <Trans>
                  la différence
                </Trans>
              </span>
              <br className="br-desktop" />
              <span className="title-home title-home-you">
                <Trans>en Afrique</Trans>
              </span>
            </Trans>
          </h1>
          <div className='become_memberPage_subtitle'>
          <p className="text-home">
            <Trans>
              Rejoignez une communauté impactante qui réalise de véritables projets en Afrique.
            </Trans>
          </p>
          <div className="flex mt-6 membershipButton">
            <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
          </div>
          </div>
        </div>

        <div className="column is-6-desktop pl-desktop-6">
          < StaticImage
              src='../images/repatalk-12.webp'
              alt="à propos de Repat Africa"
              className="predominant-image"
              loading="eager"
              width={592}
              height={362}
            />
        </div>
      </div>
    </div>
  )
}

export default ImpactAfrica;
