import React from 'react';
// import Circleeffect from '../images/Circleeffect.png';
import rightticke from '../images/rightticke.svg';
import Premiumticke from '../images/Premiumticke.svg';

import { Trans } from '@lingui/macro';



const OurPricingBecomeMember = ({ locale }) => {
  return (
    <>
      <section className='OurPricingBecomeMember-section'>
        <div className='container'>
          <div className='MembershipBonuses-heading'>
            <h4><Trans>Choisissez votre niveau d'adhésion</Trans></h4>
          </div>
          <div className='columns alignItemCenter priceingWrap'>
            <div className='is-12-mobile is-12-tablet  is-6-desktop column'>
              <div className='priceing-mainBox'>
                <div className='priceing-list'>
                  <div className='priceingDetails'>
                    <h3>Basic</h3>
                    <p>Basic</p>
                    <h2>500 €</h2>
                    <span><Trans>ou 125€ par trimestre</Trans></span>
                    <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
                  </div>
                  <div className='priceingDetails-list'>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès à la plateforme de la communauté</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès à un networking de haut niveau</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Saississez de nouvelles opportunités d'investissement</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès à tous les évènements</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès au gala annuel</Trans></p>
                    </div>

                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès aux formations</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={rightticke} alt="" />
                      <p><Trans>Accès aux opportunités de voyages immersifs</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='is-12-mobile is-12-tablet is-6-desktop column'>
              <div className='priceing-mainBox'>
                <div className='priceing-list priceing-list Premium-list'>
                  <div className='priceingDetails'>
                    <h3>Premium</h3>
                    <p>Premium</p>
                    <h2></h2>
                    <span><Trans>(sur liste d'attente)</Trans></span>
                    <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
                  </div>
                  <div className='priceingDetails-list'>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Tout ce qui est inclus dans l'offre basique</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès à des conseils personnalisés</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès prioritaire aux événements</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès à des analyses de marché exclusives</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès à nos événements internationaux</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès à des ateliers exclusifs</Trans></p>
                    </div>
                    <div className='priceingBoxDetaols'>
                      <img src={Premiumticke} alt="" />
                      <p><Trans>Accès à des conseils personnalisés</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurPricingBecomeMember;
