import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Base5 from '../images/Base5.png';
import Base6 from '../images/Base6.png';
import { Trans } from '@lingui/macro';



const EmpoweringAfricanCommunities = ({ locale }) => {
  return (
    <>
      <section className='EmpoweringAfricanCommunities-section'>
        <div className='container'>
          <div className='columns EmpoweringAfricanCommunities-wrap'>
            <div className=' is-12-mobile is-12-tablet is-6-desktop column'>
              <div className='EmpoweringAfricanCommunities-details'>
                <h4><Trans>Une communauté qui créé de la valeur en Afrique</Trans></h4>
                <div className='EmpoweringAfricanCommunities-box'>
                  <div className="benefits-details">
                    <div className="feature-details">
                      <div className='feature-img'>
                        <StaticImage
                          src='../images/Feature1.png'
                          placeholder='blurred'
                        />
                      </div>
                      <div className='featurecontant'>
                        <span><Trans>Nous créons des emplois en Afrique en investissant dans les entreprises locales.</Trans></span>
                      </div>
                    </div>
                    <div className="feature-details">
                      <div className='feature-img'>
                        <StaticImage
                          src='../images/Feature2.png'
                          placeholder='blurred'
                        />
                      </div>
                      <div className='featurecontant'>
                        <span><Trans>Nous travaillons sur les migrations en facilitant l'installation en Afrique des diasporas.</Trans></span>
                      </div>
                    </div>
                    <div className="feature-details">
                      <div className='feature-img'>
                        <StaticImage
                          src='../images/Feature3.png'
                          placeholder='blurred'
                        />
                      </div>
                      <div className='featurecontant'>
                        <span><Trans>Nous formons les jeunes africains pour améliorer leurs compétences professionnelles, entrepreneuriales et sociales.</Trans></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='is-12-mobile is-12-tablet is-6-desktop column'>
              <div className="Empoweringimgs">

                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                    src='../images/patchwork-1.webp'
                    placeholder='blurred'
                    className="radius-8"
                  />
                </div>


                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                    src='../images/patchwork-2.webp'
                    placeholder='blurred'
                    className="radius-8"
                  />
                </div>


                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                    src='../images/patchwork-3.webp'
                    placeholder='blurred'
                    className="radius-8"
                  />
                </div>


                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                      src='../images/EventDetailOne.webp'
                      placeholder='blurred'
                      className="radius-8"
                    />
                </div>


                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                    src='../images/mounir-aissatou.webp'
                    placeholder='blurred'
                    className="radius-8"

                    />
                </div>


                <div className='EmpoweringAfricanCommunities-img'>
                  <StaticImage
                    src='../images/patchwork-4.webp'
                    placeholder='blurred'
                    className="radius-8"
                    />
                </div>

              </div>
            </div>

          </div>
        </div>
      </section >
    </>
  )
}

export default EmpoweringAfricanCommunities;
