import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const WhatWeDo = ({locale}) => {
  return (
    <>
      <div className="what-we-do-container">
        <div className="container">
          <div className="columns m-0 what-we-do-Box-wrap">
            <div className="column is-12-mobile is-12-touch is-half">
              <div className="section-content ">
                <h2><Trans>Ce que nous faisons</Trans></h2>
                <p><Trans>Découvrez nos activités, notre engagement et nos efforts continus pour créer de la valeur en Afrique.</Trans></p>
                <Link className="see-more-btn" to={`/${locale}/about`}><Trans>Voir plus</Trans></Link>
              </div>
            </div>
            <div className="column is-12-mobile is-12-touch is-half">
              <div className='columns what-we-do-Box' >
              <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon trip-icon'>
                      <StaticImage
                        src='../images/Solidarity.svg'
                        alt='Solidarité'
                        title="Image solidaire de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3><Trans>Investissements</Trans></h3>
                    </div>
                    <p><Trans>Des opportunités d'investissement en Afrique sont régulièrement proposées aux membres.</Trans></p>
                  </div>
                  </div>
                </div>
                <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon message-icon'>
                      <StaticImage
                        src='../images/sms-icon.svg'
                        alt='Message'
                        title="Image message de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3 variant='h3'><Trans>Communauté</Trans></h3>
                    </div>
                    <p><Trans>Nous avons une communauté active qui échange régulièrement des ressources.</Trans></p>
                  </div>
                  </div>
                </div>
                <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon trip-icon'>
                      <StaticImage
                        src='../images/trip-icon.svg'
                        alt='Trip'
                        title="Image trip de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3><Trans>Voyages d'affaires</Trans></h3>
                    </div>
                    <p>
                      <Trans>
                        Des séjours immersifs en Afrique sont proposés aux membres pour découvrir les opportunités d'affaires.
                      </Trans>
                    </p>
                  </div>
                  </div>
                </div>
                <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon trip-icon'>
                      <StaticImage
                        src='../images/emailicon.svg'
                        alt='email'
                        title="Image email de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3><Trans>Blog & Newsletter</Trans></h3>
                    </div>
                    <p><Trans>Nos analyses sur l'économie africaine sont régulièrement partagées.</Trans></p>
                  </div>
                  </div>
                </div>
                <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                  <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon'>
                      <StaticImage
                        src='../images/eye-icon.svg'
                        alt='Oeil'
                        title="Image Oeil de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3><Trans>Recherche et Développement</Trans></h3>
                    </div>
                    <p>
                      <Trans>
                        Notre équipe est mobilisée pour positionner Repat Africa comme référence en entrepreneuriat et investissement en Afrique.
                      </Trans>
                    </p>
                  </div>
                  </div>
                </div>
                <div className='column is-12-mobile is-6-tablet is-6-desktop'>
                <div className='communityCardBox'>
                  <div className='community-card'>
                    <div className='whatWeDo-iconBox'>
                    <div className='community-card-icon trip-icon'>
                      <StaticImage
                        src='../images/youtube.svg'
                        alt='Youtube'
                        title="Image youtube de la page qui sommes nous"
                        placeholder="blurred"
                        formats={['auto', 'webp', 'avif']}
                      />
                    </div>
                    <h3><Trans>Webinaires</Trans></h3>
                    </div>
                    <p><Trans>Des rendez-vous en ligne sont organisés : formations, retours d'expériences, ateliers thématiques etc.</Trans></p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )

}

export default WhatWeDo;
