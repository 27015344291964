import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import i18n from '../../i18n.js';
import { I18nProvider } from '@lingui/react';

import Layout from '../../components/Layout.js';
import Seo from "../../components/Seo.js";

import img from '../../images/lisboa-15may-068-665f262e9d7b0.webp'
import RoadmapHeroSection from '../../components/RoadmapHeroSection.js';
import RoadMapRepatAfrica from '../../components/RoadMapRepatAfrica.js';

import Questions from '../../components/Questions.js';
import JoinCommunity from '../../components/JoinCommunity.js';


const Roadmap = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);


  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Notre Roadmap"
            description="Repat Africa est une organisation non gouvernementale (ONG) d'intérêt général engagée à promouvoir et concrétiser l'investissement en Afrique."
            image={img}
            robots="index"
          />
          <RoadmapHeroSection locale={intl.locale}/>
          <RoadMapRepatAfrica />
          <Questions />
          <JoinCommunity />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default Roadmap;
