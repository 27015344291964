import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import i18n from '../i18n';
import { I18nProvider } from '@lingui/react';
import { graphql } from 'gatsby'


import Layout from '../components/Layout';
import Seo from "../components/Seo";

import img from '../images/events.webp'
import Events from '../components/Events';
import '../styles/_event.scss';



export const query = graphql`
query EventQuery {
  events: allSanityEvent(filter: {lang: { eq: "fr"}}, sort: { fields: startAt, order: DESC }) {
    nodes {
      name
      startAt
      link
      lang
      premium
      replay
      type
      id
      description
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      guests {
        image {
          asset {
            gatsbyImageData(width: 400)
          }
        }
        name
        position
      }
    }
  }
}
`

const Thanks = ({ data }) => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <I18nProvider i18n={i18n}>
      <Layout locale={intl.locale}>
        <Seo
          lang={intl.locale}
          title="Participez à nos évènements mensuels"
          description="Rejoignez-nous pour des meetups networking, ateliers de formation, Pitch Nights organisés chaque mois pour enrichir votre expérience et réseau"
          image={img}
          robots="index, follow"
          canonical={`https://www.repat.africa/${intl.locale}/events`}
          keywords="événements, repatalk, networking night, ateliers, formation, pitch nights, diaspora africaine"
          type="website"
        />
        <Events events={data.events}/>
      </Layout>
    </I18nProvider>
  )
}

export default Thanks;
