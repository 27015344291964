import React, { useState } from 'react'
import '../styles/_questions.scss';
import { Trans } from '@lingui/macro';

const GovernanceWorkScope = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='RoadMapDetailsAgojieScope_section Question-section'>
      <div className='container'>
        <div className='RoadMapDetailsAgojieScope_Heading'>
          <h3><Trans>Objectifs de la phase Mansa Moussa</Trans></h3>
        </div>
        <div className='RoadMapDetailsAgojieScope_details'>
          <div className='columns'>
            <div className='column'>
              <div className="QuestionAccordion">
                <div className="accordion-wrapper">
                  <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="checkone" />
                    <label className="accordion-label" htmlFor="checkone"><Trans>Devenir leader mondial</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Nous établirons Repat Africa comme le leader incontesté dans l'engagement des diasporas et l'investissement en Afrique, en consolidant notre position de leader.</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="checktwo" />
                    <label className="accordion-label" htmlFor="checktwo"><Trans>Maximiser l'impact</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Nous augmenterons notre influence et notre impact économique, en soutenant activement le développement des communautés locales et en favorisant la croissance économique continentale.</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="checkthree" />
                    <label className="accordion-label" htmlFor="checkthree"><Trans>Établir un système d'innovation continue</Trans></label>
                    <div className="accordion-content">
                      <p><Trans>Nous créerons un environnement propice à l’innovation continue, permettant l’évolution des pratiques d’investissement et le développement de nouvelles solutions.</Trans></p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="checkfour" />
                    <label className="accordion-label" htmlFor="checkfour"><Trans>Devenir un modèle global</Trans></label>
                    <div className="accordion-content">
                      <p>
                        <Trans>
                          Nous positionnerons Repat Africa comme un modèle de référence pour les initiatives d’investissement et de développement durable à travers le monde, en partageant nos meilleures pratiques et en influençant les standards internationaux.
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='Consolidation_details'>
                <h3><Trans>Gouvernance 👑</Trans></h3>
                <p className="has-text-justified">
                  <Trans>
                    Avec l’atteinte de l’apogée, la phase Mansa Moussa représentera un grand bond en avant pour la communauté Repat Africa. Alors que les phases précédentes auront permis de consolider et d’étendre notre influence, la phase Mansa Moussa se concentrera sur la réalisation de notre potentiel maximal, en renforçant notre position de leader et en maximisant notre impact global.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Le travail pour la phase Mansa Moussa sera mené en parallèle avec d’autres initiatives stratégiques, et une fois achevée, elle permettra à Repat Africa de fonctionner avec une efficacité et une influence accrues. L’un des objectifs principaux de cette phase sera d’établir un système d’innovation continue et de devenir un modèle global de réussite.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous établirons Repat Africa comme le leader incontesté dans le domaine de l’investissement en Afrique et à l’international. Cette phase visera à consolider notre position sur le marché global, en renforçant notre présence et notre influence à travers des initiatives stratégiques et des partenariats clés.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous augmenterons notre influence et notre impact économique en soutenant activement le développement des communautés locales et en favorisant la croissance économique régionale. Cela comprendra des investissements stratégiques dans des projets à fort potentiel, visant à stimuler l’économie et à améliorer les conditions de vie des populations locales.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Nous positionnerons Repat Africa comme un modèle de référence pour les initiatives d’investissement et de développement durable à travers le monde. En partageant nos meilleures pratiques et en influençant les standards internationaux, nous aspirons à être reconnus comme une source d’inspiration et un leader dans notre domaine.
                  </Trans>
                  </p>
                <p className="has-text-justified">
                  <Trans>
                    En résumé, la phase Mansa Moussa se concentrera sur la réalisation de notre potentiel maximal, en devenant le leader mondial, en maximisant notre impact, en établissant un système d’innovation continue, et en devenant un modèle global de réussite. Cela nous permettra de garantir une croissance durable et une
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default GovernanceWorkScope;
