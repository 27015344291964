import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react';

import Layout from '../components/Layout.js';
import Seo from "../components/Seo.js";

import img from '../images/repat-home.png';
import { useIntl } from 'gatsby-plugin-intl';
import i18n from '../i18n.js';
import InvestmentsDetailsHeroSection from '../components/InvestmentsDetailsHeroSection.js';
import AboutInvestments from '../components/AboutInvestments.js';
import RoadMapHistory from '../components/RoadMapHistory.js';
import Questions from '../components/Questions.js';
import JoinCommunity from '../components/JoinCommunity.js';
import OurInvestmentsStatistic from '../components/OurInvestmentsStatistic.js';
import DynamicInvestmentsPlan from '../components/DynamicInvestmentsPlan.js';

const DynamicInvestments = () => {
  const intl = useIntl();

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <div>
      <I18nProvider i18n={i18n}>
        <Layout locale={intl.locale}>
          <Seo
            lang={intl.locale}
            title="Qui sommes-nous ?"
            description="Repat Africa est une organisation non gouvernementale (ONG) d'intérêt général engagée à promouvoir et concrétiser l'investissement en Afrique."
            image={img}
            robots="index"
          />
          <InvestmentsDetailsHeroSection />
          <AboutInvestments />
          <RoadMapHistory />
          <OurInvestmentsStatistic />

          <DynamicInvestmentsPlan />

          <Questions />
          <JoinCommunity />
        </Layout>
      </I18nProvider>
    </div>
  )
}

export default DynamicInvestments;