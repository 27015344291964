import React from 'react';
import { Trans } from '@lingui/macro';



const GoalsMissionTour = ({ locale }) => {
  return (
    <>
      <section className='GoalsMissionTour-section'>
        <div className='container'>
          <div className='GoalsMissionTour-box'>
            <div className='GoalsMissionTour'>
              <div className='GoalsMissionTour_heading'>
                <h3><Trans>Objectifs du séjour</Trans></h3>
              </div>
              <div className='GoalsMissionTour_headingDetails'>
                <p><Trans>Rendre votre expérience inoubliable vous plongeant dans les opportunités d'affaires africaines</Trans></p>
              </div>
            </div>
            <div className='GoalsMissionTour-number'>
              <div className='GoalsMissionTourNumberbox'>
                <div className='GoalsMissionTourNumberHedding'>
                  <h2>1</h2>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="90" height="91" viewBox="0 0 90 91" fill="none">
                    <circle opacity="0.12" cx="45" cy="45.1953" r="45" fill="#FFF2CF" />
                    <circle opacity="0.24" cx="45" cy="45.1953" r="30" fill="#FFF2CF" />
                    <circle opacity="0.5" cx="45" cy="45.1953" r="16" fill="#FFF2CF" />
                    <circle cx="45" cy="45.1953" r="8" fill="#FFF2CF" />
                  </svg></span>
                </div>
                <p><Trans>Renforcer vos liens culturels</Trans></p>
              </div>
              <div className='GoalsMissionTourNumberbox'>
                <div className='GoalsMissionTourNumberHedding'>
                  <h2>2</h2>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="90" height="91" viewBox="0 0 90 91" fill="none">
                    <circle opacity="0.12" cx="45" cy="45.1953" r="45" fill="#FFF2CF" />
                    <circle opacity="0.24" cx="45" cy="45.1953" r="30" fill="#FFF2CF" />
                    <circle opacity="0.5" cx="45" cy="45.1953" r="16" fill="#FFF2CF" />
                    <circle cx="45" cy="45.1953" r="8" fill="#FFF2CF" />
                  </svg></span>
                </div>
                <p><Trans>Promouvoir le développement économique</Trans></p>
              </div>
              <div className='GoalsMissionTourNumberbox'>
                <div className='GoalsMissionTourNumberHedding'>
                  <h2>3</h2>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="90" height="91" viewBox="0 0 90 91" fill="none">
                    <circle opacity="0.12" cx="45" cy="45.1953" r="45" fill="#FFF2CF" />
                    <circle opacity="0.24" cx="45" cy="45.1953" r="30" fill="#FFF2CF" />
                    <circle opacity="0.5" cx="45" cy="45.1953" r="16" fill="#FFF2CF" />
                    <circle cx="45" cy="45.1953" r="8" fill="#FFF2CF" />
                  </svg></span>
                </div>
                <p><Trans>Vous et sensibiliser aux défis et opportunités</Trans></p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default GoalsMissionTour;
