import React from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

const RoadmapHeroSection = ({locale}) => {
  return (
    <section className='RoadmapHero-Section'>
      <div className='container padding-2'>
        <div className='RoadmapHero'>
          <h2 className="title-home">Roadmap</h2>
          <p className="has-text-justified">
            <Trans>
              Notre roadmap présente les étapes clés de notre projet, inspirées par les grandes figures historiques africaines. Chaque illustration ci-dessous représente une phase clé de notre parcours.
            </Trans>
          </p>
        </div>
      </div>
      <div className='RoadmapHeroMain'>
        <div className='container'>
          <div className='columns'>
            <div className='is-3 column'>
              <Link to={`/${locale}/roadmap/oseitutu#section-heading`}>
                <div className='RoadmapHeroMain-Box'>
                  <div className='box-shap'></div>
                  <div className='RoadmapHeroBox-img'>
                    <StaticImage
                      src='../images/oseitutu.webp'
                      title="Osei Tutu"
                      alt="Illustration de Osei Tutu, un leader historique africain"
                      loading="eager"
                      placeholder="blurred"
                      formats={['auto', 'webp', 'avif']}
                    />
                  </div>
                  <div className='RoadmapHeroBox-details'>
                    <h4>1. Osei Tutu</h4>
                    <p><Trans>Fondation</Trans></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='is-3 column'>
              <Link to={`/${locale}/roadmap/agojiedahomey#section-heading`}>
                <div className='RoadmapHeroMain-Box brown-box'>
                  <div className='box-shap'></div>
                  <div className='RoadmapHeroBox-img'>
                    <StaticImage
                      src='../images/agojie.webp'
                      title="Agojie Dahomey"
                      alt="Illustration des Agojie du dahomey, des firures historiques africaines"
                      loading="eager"
                      placeholder="blurred"
                      formats={['auto', 'webp', 'avif']}
                    />
                  </div>
                  <div className='RoadmapHeroBox-details'>
                    <h4>2. Agojie Dahomey</h4>
                    <p><Trans>Consolidation</Trans></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='is-3 column'>
              <Link to={`/${locale}/roadmap/shakazulu#section-heading`}>
                <div className='RoadmapHeroMain-Box green-box'>
                  <div className='box-shap'></div>
                  <div className='RoadmapHeroBox-img'>
                    <StaticImage
                      src='../images/shakazulu.webp'
                      title="Shaka Zulu"
                      alt="Illustration de Shaka Zulu, un e firure historiques africaine"
                      loading="eager"
                      placeholder="blurred"
                      formats={['auto', 'webp', 'avif']}
                    />
                  </div>
                  <div className='RoadmapHeroBox-details'>
                    <h4>3. Shaka Zulu</h4>
                    <p><Trans>Expansion</Trans></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className='is-3 column'>
              <Link to={`/${locale}/roadmap/mansamoussa#section-heading`}>
                <div className='RoadmapHeroMain-Box red-box'>
                  <div className='box-shap'></div>
                  <div className='RoadmapHeroBox-img'>
                    <StaticImage
                      src='../images/mansamoussa.webp'
                      title="Mansa Moussa"
                      alt="Illustration de Mansa Moussa, l'homme le plus riche de tous les temps"
                      loading="eager"
                      placeholder="blurred"
                      formats={['auto', 'webp', 'avif']}
                    />
                  </div>
                  <div className='RoadmapHeroBox-details'>
                    <h4>4. Mansa Moussa</h4>
                    <p><Trans>Gouvernance</Trans></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RoadmapHeroSection
