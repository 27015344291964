import React from 'react';
import { Trans } from '@lingui/macro'
import { StaticImage } from 'gatsby-plugin-image';

import meetBoxone from '../images/meetBox1.png';
import Students from '../images/Students.png';
import Africanfamilies from '../images/Africanfamilies.png';
import professions from '../images/professions.png';
import Political from '../images/Political.png';
import Athletes from '../images/Athletes.png';
import Sellers from '../images/Sellers.png';
import physical from '../images/physical.png';
import Slider from 'react-slick';


const LastTour = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var meetBox = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className='LastTour-section'>
        <div className='container'>
          <div className='LastTourHeading'>
            <h2><Trans>Avis des participants</Trans></h2>
            <p><Trans>Découvrez pourquoi vous devez vivre cette expérience</Trans></p>
            <div>
              <Slider {...settings}>
                <div>
                  <div className='LastTour_sider_Box'>
                    <div className='LastTour_sider'>
                      <div className='lasttour_slider_details'>
                        <div className='lastTour_heading'>
                          <div className='lastTour_user'>
                            <div className='lastTour_user_img'>
                              <StaticImage
                                src='../images/TeamMember/jeremy.webp'
                                alt="Jérémy"
                                placeholder="blurred"
                                formats={['auto', 'webp', 'avif']}
                              />
                            </div>
                            <div className='lastTour_user_details'>
                              <h5>Jérémy François</h5>
                              <p><Trans>Participant en 2023</Trans></p>
                            </div>
                          </div>
                          <div className='lastTourAbout'>
                            <h3><Trans>Je le referais 10 fois si possible !</Trans></h3>
                            <br/>
                            <p className="is-italic has-text-justified"><Trans>Aspect culturel : cela m'a permis de découvrir l'Afrique de l'ouest à travers 2 pays (le Sénégal et la Côte d’Ivoire) et de voir les similitudes avec ma culture antillaise. Cela m'a permis de comprendre qu'on se ressemblait beaucoup plus qu'on ne le pense, et que nos "pseudos" différences étaient juste dues à un manque de connaissance de l'autre.</Trans></p>
                            <p className="is-italic has-text-justified"><Trans>Aspect économique : nous avons rencontré plus d'une 20aine d'acteurs de milieux différents ; des agriculteurs aux responsables d'incubateur, en passant par les politiques. Cela m'a permis de comprendre le maillage de chaque pays et les opportunités auxquelles on pouvait prendre part.</Trans></p>
                            <p className="is-italic has-text-justified"><Trans>Aspect humain : j'ai noué de vraies relations avec certains membres du Repat Tour, et ce voyage m'a permis de mieux comprendre la vision commune qui nous liait, ainsi que le but vers lequel on avance tous ensemble.</Trans></p>

                          </div>
                        </div>
                      </div>
                      <div className='lasttour_slider_video'></div>
                    </div>
                    <div className='lastTour_video'>
                      <div className='lastTour_video_box'>
                        <StaticImage
                          src='../images/jeremy.webp'
                          alt="Jérémy"
                          placeholder="blurred"
                          formats={['auto', 'webp', 'avif']}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  <div className='LastTour_sider_Box'>
                    <div className='LastTour_sider'>
                      <div className='lasttour_slider_details'>
                        <div className='lastTour_heading'>
                          <div className='lastTour_user'>
                            <div className='lastTour_user_img'>
                              <StaticImage
                                src='../images/TeamMember/mawena.webp'
                                alt="Mawéna"
                                placeholder="blurred"
                                formats={['auto', 'webp', 'avif']}
                              />
                            </div>
                            <div className='lastTour_user_details'>
                              <h5>Mawéna Rainard</h5>
                              <p><Trans>Participante en 2023</Trans></p>
                            </div>
                          </div>
                          <div className='lastTourAbout'>
                            <h3><Trans>Bien plus qu'un simple voyage !</Trans></h3>
                            <br/>
                            <p className="is-italic has-text-justified"><Trans>Cette expérience a dépassé mes attentes : je savais que ce voyage allait m’apporter beaucoup grâce aux rencontres et aux visites sur place mais j’ignorais que les bénéfices allaient s’étendre sur le long terme.</Trans></p>
                            <p className="is-italic has-text-justified"><Trans>Nous avions un programme chargé : une semaine au Sénégal suivie d’une semaine en Côte d’Ivoire agrémentées de nombreuses activités et rencontres pour nous faire comprendre les réalités du terrain. Ce qui m’a particulièrement plu, c’est le fait de pouvoir échanger avec des personnes à différentes échelles (du fermier local au politicien national) et ainsi créer sa propre idée de la réalité locale.</Trans></p>
                            <p className="is-italic has-text-justified"><Trans>J’ai beaucoup apprécié le fait que nous avons pu découvrir la culture du nord de la Côte d’Ivoire en ne restant pas seulement à Abidjan mais en allant à Yamoussoukro, Bouake, Korhogo.</Trans></p>

                          </div>
                        </div>
                      </div>
                      <div className='lasttour_slider_video'></div>
                    </div>
                    <div className='lastTour_video'>
                      <div className='lastTour_video_box'>
                        <StaticImage
                          src='../images/mawena.webp'
                          alt="Jérémy"
                          placeholder="blurred"
                          formats={['auto', 'webp', 'avif']}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  <div className='LastTour_sider_Box'>
                    <div className='LastTour_sider'>
                      <div className='lasttour_slider_details'>
                        <div className='lastTour_heading'>
                          <div className='lastTour_user'>
                            <div className='lastTour_user_img'>
                              <StaticImage
                                src='../images/TeamMember/mariam.webp'
                                alt="Mariam"
                                placeholder="blurred"
                                formats={['auto', 'webp', 'avif']}
                              />
                            </div>
                            <div className='lastTour_user_details'>
                              <h5>Mariam Fofana</h5>
                              <p><Trans>Participante en 2023</Trans></p>
                            </div>
                          </div>
                          <div className='lastTourAbout'>
                            <h3><Trans>Mémorable et authentique</Trans></h3>
                            <br/>
                            <p className="is-italic"><Trans>C'était une expérience mémorable et authentique. Une aventure tout aussi bouleversante émotionnellement que professionnellement. Cela m'a permis d'accéder à un réseau et à des personnalités de qualité tels que le ministre du tourisme de la Côte d'Ivoire et des entrepreneurs de haute envergure engagés sur le continent africain (Stanislas Zeze, Thione Niang) auxquels on n'aurait pas eu accès autrement.</Trans></p>
                            <p className="is-italic"><Trans>J'ai pu découvrir le Sénégal et la Côte d'Ivoire sous un aspect business et y découvrir concrètement les nombreuses opportunités d'investissement et entrepreneuriales dans divers secteurs d'activité (agrobusiness, industries...). Ce voyage en immersion m'a confirmé mon engagement et ma contribution au développement socio-économique de l'Afrique ainsi que ma future repatriation sur le continent. Merci Repat Africa !</Trans></p>
                          </div>
                        </div>
                      </div>
                      <div className='lasttour_slider_video'></div>
                    </div>
                    <div className='lastTour_video'>
                      <div className='lastTour_video_box'>
                        <StaticImage
                          src='../images/mariam.webp'
                          alt="Mariam"
                          placeholder="blurred"
                          formats={['auto', 'webp', 'avif']}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className='WhoDoWeMeetWith'>
          <div className='container'>
            <div className='columns WhoDoWeMeetWith_flex'>
              <div className='is-12-tablet is-12-touch is-5-desktop column WhoDoWeMeetWith_BoxOne'>
                <div className='LastTourHeading'>
                  <h2><Trans>Qui allez vous rencontrer ?</Trans></h2>
                  <p>
                    <Trans>
                      Vous aurez l'opportunité de rencontrer des personnalités politiques, des entrepreneurs, et des repats établis en Afrique. Chaque journée vous offre de nouvelles découvertes et la possibilité d'établir de précieux contacts.
                    </Trans>
                  </p>
                  <p><Trans>Participez à cette aventure inoubliable qui enrichit votre réseau et vous offre de nouvelles opportunités.</Trans></p>
                  <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
                </div>
              </div>
              <div className='is-12-tablet is-12-touch is-7-desktop column WhoDoWeMeetWith_BoxTwo'>
                <div className='meetBox'>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={meetBoxone} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Entrepreneurs locaux</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={Students} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Investisseurs</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={Africanfamilies} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Chefs traditionnels</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={professions} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Artistes et créateurs</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={Political} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Personnalités politiques</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={Athletes} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Repats installés sur place</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={Sellers} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Leaders d'opinion</Trans></p>
                    </div>
                  </div>
                  <div className='meetmainbox'>
                    <div className='meetBox-img'>
                      <img src={physical} />
                    </div>
                    <div className='meetBox-details'>
                      <p><Trans>Experts en développement économique</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='WhoDoWeMeetWith-responcive'>
                <Slider {...meetBox}>
                  <div className='meetBox'>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={meetBoxone} />
                      </div>
                      <div className='meetBox-details'>
                        <p>Young businessmen</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={Students} />
                      </div>
                      <div className='meetBox-details'>
                        <p>Students</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={Africanfamilies} />
                      </div>
                      <div className='meetBox-details'>
                        <p>African families</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={professions} />
                      </div>
                      <div className='meetBox-details'>
                        <p>People of creative professions</p>
                      </div>
                    </div>
                  </div>
                  <div className='meetBox'>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={Political} />
                      </div>
                      <div className='meetBox-details'>
                        <p>Political figures</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={Athletes} />
                      </div>
                      <div className='meetBox-details'>
                        <p>Athletes</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={Sellers} />
                      </div>
                      <div className='meetBox-details'>
                        <p>Sellers of various services</p>
                      </div>
                    </div>
                    <div className='meetmainbox'>
                      <div className='meetBox-img'>
                        <img src={physical} />
                      </div>
                      <div className='meetBox-details'>
                        <p>People with physical disabilities</p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default LastTour;
