import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { globalHistory } from "@reach/router"


const Navbar = ({ locale, location }) => {
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const path = globalHistory.location.pathname;
  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container nb-container">
          <div className="navbar-brand">
            <Link to={locale === "fr" ? "/fr/" : "/en/"} id="repat-logo-link">
              <div className="mt-2 logo-desktop">
                <StaticImage
                  src='../images/repat-africa.svg'
                  alt="repat africa desktop logo"
                  loading="eager"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                />
              </div>
              <div className="mt-2 logo-mobile">
                <StaticImage
                  src='../images/mobile-logo.png'
                  alt="repat africa mobile logo"
                />
              </div>
            </Link>

            <Link to={locale === "fr" ? "/fr/" : "/en/"} className="navbar-item home-menu-responcive">
              <div className="home-menu">
                <p>Home</p>
              </div>
            </Link>

            <a
              onClick={() => { setIsBurgerActive(!isBurgerActive) }} role="button"
              tabIndex={0}
              className={`navbar-burger burger ${isBurgerActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">

              <StaticImage
                src='../images/menu.svg'
                alt="Menu Svg"
              />
            </a>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${isBurgerActive ? "is-active" : ""}`}>
            <div className="navbar-start">

              <Link to={locale === "fr" ? "/fr/" : "/en/"} className="navbar-item">
                <div className="home-filled">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <path d="M22.6669 10.6627L16.4451 5.82337C15.8991 5.39859 15.227 5.16797 14.5352 5.16797C13.8435 5.16797 13.1714 5.39859 12.6254 5.82337L6.4024 10.6627C6.02842 10.9535 5.72585 11.326 5.51779 11.7516C5.30972 12.1772 5.20168 12.6448 5.2019 13.1185V21.5185C5.2019 22.1374 5.44774 22.7309 5.88532 23.1685C6.32291 23.606 6.9164 23.8519 7.53524 23.8519H21.5352C22.1541 23.8519 22.7476 23.606 23.1852 23.1685C23.6227 22.7309 23.8686 22.1374 23.8686 21.5185V13.1185C23.8686 12.1584 23.4252 11.2519 22.6669 10.6627Z" fill="#ECAE4C" stroke="#ECAE4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </Link>


              <Link to={`/${locale}/about/`} className="navbar-item">
                <Trans>À propos de nous</Trans>
              </Link>

              <Link to={`/${locale}/events/`} className="navbar-item">
                <Trans>Évènements</Trans>
              </Link>

              <Link to={`/${locale}/roadmap/`} className="navbar-item">
                <Trans>Roadmap</Trans>
              </Link>

              <Link to={`/${locale}/repat-africa-tour/`} className="navbar-item">
                <Trans>Repat Africa Tour</Trans>
              </Link>

              <Link to={`/${locale}/investments/`} className="navbar-item">
                <Trans>Investissements</Trans>
              </Link>

              <div className="buttons ml-1rem">
                <Link to={`/${locale}/join/`} className="navbar-join-btn" id="navbar-btn-member">
                  <div className='navbar-join-btn-text'>
                    <Trans>Devenir membre</Trans>
                  </div>
                </Link>
              </div>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link">
                    {locale === "fr" && (
                      <StaticImage
                        src='../images/france.png'
                        alt="en"
                        width={20}
                      />
                    )}

                    {locale === 'en' && (
                      <StaticImage
                        src='../images/royaume-uni.png'
                        alt="en"
                        width={20}
                      />
                    )}
                  </a>

                  <div className="navbar-dropdown">
                    <Link to={path.includes("/fr/") ? path.replace("/fr/", "/en/") : path.replace("/en/", "/fr/")} className="navbar-item" style={{ display: "flex" }}>

                      {locale === 'fr' && (
                        <>
                          <StaticImage
                            src='../images/royaume-uni.png'
                            alt="en"
                            width={20}
                          />
                          <div>&nbsp;</div>
                          <span>English</span>

                        </>
                      )}

                      {locale === 'en' && (
                        <>
                          <StaticImage
                            src='../images/france.png'
                            alt="en"
                            width={20}
                          />
                          <div>&nbsp;</div>
                          <span>Français</span>

                        </>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
export default Navbar;
