import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from '@lingui/macro';


const BecomeMember = ({ locale }) => {

  return (
    <>
      <section className='BecomeMember-section'>
        <div className='container'>
          <div className='columns align-end is-vcentered is-centered'>
            <div className='is-6-desktop column pl-dekstop-6'>
              <div className='become-img'>
                <StaticImage
                  src='../images/why-repat-member.webp'
                  className="radius-8"
                  width={572}
                  height={362}
                />

              </div>
            </div>
            <div className='is-5-desktop column becomeMain-details'>
              <div className='become-details'>
                <h4><Trans>Pourquoi vous devez devenir membre ?</Trans></h4>
                <p className="has-text-justified">
                  <Trans>Vous <strong>connecterez</strong> avec des personnalités politiques, des entrepreneurs <strong>innovants</strong> et d'autres membres de la diaspora.</Trans>&nbsp;
                  <Trans>Vous accéderez à des ressources exclusives, des <storng>opportunités d'investissement</storng> dans des startups et PME africaines prometteuses.</Trans>&nbsp;
                  <Trans>Vous aurez la chance de <strong>découvrir</strong> l'Afrique sous un nouvel angle lors de nos séjours d'affaires immersifs.</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='round_progressBar'>
            <div className='greenProgressbar'>
              <div className='progressDetails'>
                <h2>30</h2>
                <p><Trans>Origines africaines représentées</Trans></p>
              </div>
            </div>
            <div className='greenProgressbar pinkProgreeBar'>
              <div className='progressDetails'>
                <h2>300K</h2>
                <p><Trans>Levés pour investir dans des startups et PME africaines</Trans></p>
              </div>
            </div>
            <div className='greenProgressbar redProgreeBar'>
              <div className='progressDetails'>
                <h2>98%</h2>
                <p><Trans>De taux de satisfaction des membres</Trans></p>
              </div>
            </div>
            <div className='greenProgressbar yellowProgreeBar'>
              <div className='progressDetails'>
                <h2>3</h2>
                <p><Trans>Pays africains ou nous sommes installés</Trans></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BecomeMember;
