import React, { useState } from 'react'
import '../styles/_questions.scss';
import { Trans } from '@lingui/macro';
const ConsolidationWorkScope = () => {
  const initialActiveIndex = Array.from({ length: 6 }, (_, index) => ({
    index,
    isActive: false
  }));

  const [activeIndex, setIsActiveIndex] = useState(initialActiveIndex);

  const handleClick = (index) => {
    setIsActiveIndex((prev) => {
      return prev.map((item) => {
        if (item.index === index) {
          return { ...item, isActive: !item.isActive };
        }
        return { ...item, isActive: false };;
      });
    });
  };

  return (
    <section className='RoadMapDetailsAgojieScope_section Question-section'>
      <div className='container'>
        <div className='RoadMapDetailsAgojieScope_Heading'>
          <h3><Trans>Actions de la phase Agojie Dahomey</Trans></h3>
        </div>
        <div className='RoadMapDetailsAgojieScope_details'>
          <div className='columns'>
            <div className='column'>
              <div className="QuestionAccordion">
                <div className="accordion-wrapper">
                  <div className={`accordion ${activeIndex[0].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(0)} name="checkbox-1" id="checkone" />
                    <label className="accordion-label" for="checkone"><Trans>Renforcer l’organisation</Trans></label>
                    <div className="accordion-content">
                      <p>
                        <Trans>
                          Nous mettons en place des structures internes pour améliorer l’efficacité et la coordination de notre équipe, garantissant ainsi une gestion optimale de nos projets.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[1].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(1)} name="checkbox-2" id="checktwo" />
                    <label className="accordion-label" for="checktwo"><Trans>Améliorer les services</Trans></label>
                    <div className="accordion-content">
                    <p>
                      <Trans>
                        Nous optimisons les fonctionnalités existantes et introduisons de nouvelles offres pour mieux répondre aux besoins de notre communauté, assurant ainsi une expérience utilisateur améliorée.
                      </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[2].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(2)} name="checkbox-3" id="checkthree" />
                    <label className="accordion-label" for="checkthree"><Trans>Étendre notre portée</Trans></label>
                    <div className="accordion-content">
                    <p>
                      <Trans>
                        Nous travaillons à l’expansion vers de nouveaux marchés et augmentons la visibilité de Repat Africa à travers diverses initiatives, afin de toucher un public plus large.
                      </Trans>
                      </p>
                    </div>
                  </div>
                  <div className={`accordion ${activeIndex[3].isActive ? "active-accordion" : 'inactive-accordion'}`}>
                    <input type="checkbox" onChange={(e) => handleClick(3)} name="checkbox-4" id="checkfour" />
                    <label className="accordion-label" htmlFor="checkfour"><Trans>Améliorer l'engagement</Trans></label>
                    <div className="accordion-content">
                      <p>
                        <Trans>
                          Nous lançons des initiatives pour renforcer l’implication et la collaboration au sein de la communauté Repat Africa, favorisant ainsi un sentiment d’appartenance et de solidarité.
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='Consolidation_details'>
                <h3><Trans>Consolidation</Trans></h3>
                <p className="has-text-justified">
                  <Trans>
                    Avec la phase de consolidation, la phase Agojie Dahomey représente un grand pas en avant pour la communauté Repat Africa. Alors que la phase Osei Tutu a posé les bases de notre projet, la phase Agojie Dahomey se concentre sur le renforcement et l’optimisation de nos acquis, en consolidant notre structure organisationnelle et en améliorant nos services.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Le travail pour la phase Agojie Dahomey se déroule en parallèle avec nos autres initiatives, et une fois achevée, elle permettra aux membres de notre communauté de bénéficier d’une plateforme robuste et bien structurée. L’un des objectifs principaux de cette phase est de renforcer notre organisation interne et d’améliorer l’efficacité de nos processus.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    L’expansion de notre portée est également un objectif clé de cette phase. Nous cherchons à étendre notre influence vers de nouveaux marchés et à augmenter la visibilité de Repat Africa à travers diverses initiatives. Cela nous permet de toucher un public plus large et de renforcer notre présence à l’échelle mondiale.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    Enfin, nous lançons des initiatives pour renforcer l’implication et la collaboration au sein de la communauté Repat Africa. Ces initiatives visent à favoriser un sentiment d’appartenance et de solidarité, en encourageant les membres à participer activement et à contribuer au développement de notre projet.
                  </Trans>
                </p>
                <p className="has-text-justified">
                  <Trans>
                    En résumé, la phase Agojie Dahomey se concentre sur la consolidation des bases posées par la phase Osei Tutu, en renforçant notre organisation, en améliorant nos services, en étendant notre portée et en engageant activement notre communauté. Cela nous permet de garantir une croissance durable et une base solide pour nos futures expansions.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default ConsolidationWorkScope;
