/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// gatsby-browser.js

// gatsby-browser.js

import "./src/styles/styles.scss";

export const onClientEntry = () => {
  const linkPoppins = document.createElement("link");
  linkPoppins.href = "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap";
  linkPoppins.rel = "stylesheet";
  document.head.appendChild(linkPoppins);

  const linkNunito = document.createElement("link");
  linkNunito.href = "https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap";
  linkNunito.rel = "stylesheet";
  document.head.appendChild(linkNunito);

  const linkMontserrat = document.createElement("link");
  linkMontserrat.href = "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,800&display=swap";
  linkMontserrat.rel = "stylesheet";
  document.head.appendChild(linkMontserrat);

  const linkOpenSans = document.createElement("link");
  linkOpenSans.href = "https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap";
  linkOpenSans.rel = "stylesheet";
  document.head.appendChild(linkOpenSans);
};
