import React from 'react';
import { useMediaQuery } from 'react-responsive';

import ImpactAfrica from './ImpactAfrica';
import BecomeMember from './BecomeMember';
import EmpoweringAfricanCommunities from './EmpoweringAfricanCommunities';
import BecomeMemberTestimonials from './BecomeMemberTestimonials';
import MembershipBonuses from './MembershipBonuses';
import ConditionsBecomeMember from './ConditionsBecomeMember';
import OurPricingBecomeMember from './OurPricingBecomeMember';
import DevelopKey from './DevelopKey';
import JoinCommunityBecome from './JoinCommunityBecome';
import Questions from './Questions';
import JoinCommunity from './JoinCommunity';

import '../styles/_becomeMember.scss';

const Membership = ({ locale }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return (
    <>
      <ImpactAfrica locale={locale} />
      <BecomeMember locale={locale} />
      <EmpoweringAfricanCommunities locale={locale} />
      <MembershipBonuses locale={locale} />
      <ConditionsBecomeMember locale={locale} />
      <OurPricingBecomeMember locale={locale} />
      <DevelopKey locale={locale} />

      {isNotMobile && <BecomeMemberTestimonials locale={locale} />}

      <Questions locale={locale} />
      <JoinCommunity locale={locale} />
    </>
  );
}

export default Membership;
