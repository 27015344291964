import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';

import ArchiveEvents from './ArchiveEvents.js';
import Questions from './Questions';
import JoinCommunity from './JoinCommunity';


const Events = ({ events }) => {
  const onlineEvents = events.nodes.filter(event => event.type === "online");
  const physicalEvents = events.nodes.filter(event => event.type === "physical");

  let event = [];

  const [activeEventType, setActiveEventType] = useState('online_event');

  if (activeEventType === "online_event") {
    event = onlineEvents
  } else {
    event = onlineEvents
  }

  return (
    <>
      <div className='container eventPage padding-2'>
        <div className="columns eventPage-homesection is-centered">
          <div className="is-12-mobile is-12-tablet is-5-desktop column">
            <h1 className="title-home lh-40 fw-800">
              <Trans>
                <span className="title-home title-home-africa"><Trans>Nos</Trans>&nbsp;</span>
                <span className="title-home title-home-is-calling">events</span>
                <br className="br-desktop" />
                <span className="title-home title-home-you">privés</span>
              </Trans>
            </h1>
            <div className='eventResponcivebanner'>
              <StaticImage
                  src='../images/events.webp'
                  alt="Fatou et Oumou, membres Repat Africa"
                  loading="eager"
                  placeholder="blurred"
                  formats={['auto', 'webp', 'avif']}
                  className="predominant-image"
                />
            </div>
            <p className="text-home a has-text-justified">
              <Trans>
                Nous organisons des événements <strong>mensuels</strong>. Talks, concours de pitchs, ateliers de brainstorming : ces événements sont conçus pour permettre aux membres de la diaspora de se <strong>connecter</strong>, partager des idées et renforcer leurs <strong>réseaux</strong>.
              </Trans>
            </p>
            <div className="flex mt-6 membershipButton">
              <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
            </div>
          </div>

          <div className="is-6-desktop pl-desktop-6 column home-img-wrapper eventhomebanner Quisommes">
            <StaticImage
              src="../images/events.webp"
              alt="Fatou et Oumou, membres Repat Africa"
              loading="eager"
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              className="radius-8 predominant-image"
              width={592}
              height={362}
            />
          </div>

        </div>
      </div>

      <ArchiveEvents event={event} />
      <Questions />
      <JoinCommunity />
    </>
  )
}

export default Events;
