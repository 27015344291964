import React, { useEffect, Suspense, lazy } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import { useMediaQuery } from 'react-responsive';
import i18n from '../i18n';
import { I18nProvider } from '@lingui/react';
import img from '../images/why-repat-member.webp'
import Layout from '../components/Layout';
import Home from '../components/Home';
import Seo from "../components/Seo";
import WhoWeAre from '../components/WhoWeAre';
import WhatWeDo from '../components/WhatWeDo';
import HomePageEvents from '../components/HomePageEvents';
import Benefits from '../components/Benefits';
import Questions from '../components/Questions';
import JoinCommunity from '../components/JoinCommunity';

const Feedbacks = lazy(() => import('../components/Feedbacks'));

const IndexPage = () => {
  const intl = useIntl();
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    i18n.activate(intl.locale);
  }, [intl.locale]);

  return (
    <I18nProvider i18n={i18n}>
      <Layout locale={intl.locale}>
        <Seo
          lang={intl.locale}
          title="L'Engagement de la Diaspora Africaine"
          description="Découvrez comment la diaspora africaine transforme l'entrepreneuriat et le développement économique en Afrique. Rejoignez-nous pour soutenir cette dynamique."
          image={img}
          robots="index, follow"
          canonical={`https://www.repat.africa/${intl.locale}`}
          keywords="diaspora africaine, entrepreneuriat, développement économique, Afrique, Retour en Afrique, communauté"
          type="website"
        />
        <Home locale={intl.locale} />
        <WhoWeAre />
        <WhatWeDo locale={intl.locale} />
        <HomePageEvents locale={intl.locale} />
        {isNotMobile && (
          <Suspense fallback={<div>Chargement...</div>}>
            <Feedbacks locale={intl.locale} />
          </Suspense>
        )}
        <Benefits locale={intl.locale} />
        <Questions locale={intl.locale} />
        <JoinCommunity locale={intl.locale} />
      </Layout>
    </I18nProvider>
  );
}

export default IndexPage;
