import React from 'react';
import { Trans } from '@lingui/macro'





const ParticipateTour = () => {
  return (
    <>
      <section className='participateTour-section mb-3'>
        <div className='container'>
          <div className='participateTour-headning'>
            <h2><Trans>Rejoignez-nous pour participer au prochain séjour</Trans></h2>
            <p><Trans>Élargissez votre vision de l'Afrique</Trans></p>
            <div className='participateTour-button'>
              <a className="button-membership" href="https://eu.jotform.com/form/223365228500348" target="_blank" rel="noopener noreferrer"><Trans>Devenir membre</Trans></a>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ParticipateTour;
